
.btn--grey-art {

  font-size: 0.88rem;

  font-family: 'geomanistbold', 'Lato', sans-serif;

  background-color: #bbb;

  padding: 4px 8px;

  border-radius: 20px;

  border: none;

  color: white;

  margin: 0 5px;

  transition: background-color 0.3s;

  &:disabled {
    opacity: 0.3;
  }

  &:hover {
    background-color: $colors-V2-blue2;
  }

}