
form {
  margin: 0;
  box-sizing: border-box;
}


input[type="text"], input[type="email"], input[type="password"], input[type="date"], input[type="number"], textarea {
  padding: 8px 8px;
  border: 1px solid #bbb;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  color: #333;

  width: 250px;

  transition: background-color 0.4s;
}

.input--v2 {
  box-sizing: content-box;
  padding: 0 25px !important;
  height: 50px !important;
  border-radius: 30px !important;
  border: 1px solid #bbb !important;
  &--full-width {
    width: 95% !important;
    margin: auto;
  }
}

.textarea--v2 {
  box-sizing: content-box;
  text-align: left !important;
  padding: 15px 25px !important;
  height: 240px !important;
  border-radius: 30px !important;
  border: 1px solid #bbb !important;
  &--full-width {
    width: 95% !important;
    margin: auto;
  }
}

input[type="checkbox"] {
  margin: 6px;
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
  &:hover {
    cursor: pointer;
  }
}

.input--wide {
  box-sizing: border-box;
  width: 100%;
  max-width: 450px;
}

.input--wide2 {
  box-sizing: border-box;
  width: 100%;
  max-width: 650px;
}

.input--wide--flex {
  box-sizing: border-box;
  width: 500px;
}

.textarea--wide {
  box-sizing: border-box;
  width: 90%;
  max-width: 650px;
  height: 200px;

  &--2 {
    box-sizing: border-box;
    width: 90%;
    //max-width: 650px;
    height: 320px;
    text-align: left;
  }

  //font-family: 'Lato', sans-serif;
  font-size: 0.9rem;
}

select {
  padding: 8px 8px;
  border: 1px solid #bbb;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  color: #333;
  background-color: white;
}

.input {
  &--wrong {
    background: #ffaab4 !important;
  }
  &--warn {
    background: #ffccb4 !important;
  }
  &--ok {
    background: #b8f0c4 !important;
  }
  &--bordered {
    border: 2px solid $dominant1 !important;
  }
}

.check-box {
  position: relative;
  bottom: 2px;
  dsiplay: inline;
  width: 20px;
  height: 20px;
  border: 1px solid #bbb;
  border-radius: 3px;
  margin: 5px 10px 5px 0px;
  background-color: #eee;

  transition: all 0.2s;

  &--checked {
    background-color: $dominant1;
  }
}

.modern-input {

  border: none;
  border-bottom: 1px solid #bbb;
  font-size: 1.3rem;
}


.input-file {

  opacity: 0;
  position: relative;
  z-index: -1;

  overflow: hidden;

  &-label {
    cursor: pointer;
  }
}

.grey-bloc {
  background-color: $grey;
  padding: 15px;
  border-radius: 15px;
}