

/**
* -------------------------------------------
* Main navbar, on top of everything
*/
.business-page__navbar-main {

  position: fixed;
  top: 0;

  z-index: 10000;

  background-color: transparent;

  transition: all 0.5s;
  width: 100%;

  height: 80px;

  @include sm-only {
    height: 50px;
  }

  padding: 0 120px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &__logo {
    width: 35px;
    height: 35px;
    //opacity: 0.22;
    margin: 0 16px;
    position: relative;
    transition: all 0.2s;
    transform: rotateZ(-10deg);
  }

  &__caption {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    //opacity: 0.40;

    font-size: 1rem;
  }

  &__caption2 {
    color: grey;
    font-size: 0.8rem;
  }

  &--white {
    //background-color: #fffe;
    //background-image: linear-gradient(to bottom, #ffff, #fffe);
  }

  &--scrolled {
    background-color: #fffe;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.18);
    & .business-page__navbar-main__logo {
      width: 27px;
      height: 27px;
      margin: 0 12px;
    }
    & .business-page__navbar-main__caption {
      font-size: 0.85rem;
    }
  }

  @include sm-only {
    padding: 0 12px;

    &__logo {
      width: 22px;
      height: 22px;
      //opacity: 0.22;
      margin: 0 10px;
      //position: relative;
      //transition: all 0.2s;
      //transform: rotateZ(-10deg);
    }

    &__caption {
      font-family: 'geomanistblack', 'Lato', sans-serif;
      //opacity: 0.40;
      font-size: 0.85rem;
    }

    &__caption2 {
    }
  }
}


/**
* -------------------------------------------------
* Navbar to navigate in the business page
*/
.business-page__navbar {

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 80px;

  height: 60px;

  width: 100%;

  background-color: rgba(226, 232, 234, 0.93);

  z-index: 9998;

  //display: flex;
  //flex-flow: row nowrap;
  //justify-content: center;
  //align-items: center;


  &__links {

    margin: auto;
    width: 95%;
    max-width: 1200px;
    height: 100%;

    padding-left: 270px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @include sm-only {
      padding: 0 0 0 0;
      justify-content: center;
    }

    &__link {

      margin-right: 30px;

      @include sm-only {
        margin: 0 10px;
      }

      & button {
        font-family: 'geomanistbold', 'Lato', sans-serif !important;
        color: black;
        letter-spacing: 0.2px;
        font-size: 1rem;

        @include sm-only {
          font-size: 0.9rem;
        }
      }

      &--active {
        & button {
          color: $dominant2;
        }
      }


    }
  }


  @include sm-only {
    top: 50px;
  }
}


.business-page__small-icon {
  width: 20px;
  height: 20px;
  margin: 5px;
  &--inverted {
    filter: invert(100%);
  }
}
