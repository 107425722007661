
.invite__avatar {
  width: 220px;
  height: 220px;
  border-radius: 110px;
}

.invite__title {

  font-family: 'geomanistblack', 'Lato', sans-serif;

  font-size: 3rem;
}