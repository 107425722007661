
.btn-splash {

  //font-family: 'Lato', sans-serif;
  font-size: 1.5rem;

  background-color: white;
  color: $dominant2;
  border: none;
  font-weight: bolder;

  border-radius: 14px;

  padding: 16px 28px;

  @include sm-only {
    font-size: 1rem;
    padding: 13px 23px;
  }

  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 3px 20px 0px rgba(white,0.5);
  }

}