.welcome-div {

  z-index: 50000000;

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  &__window {

    position: relative;

    width: 100%;
    //max-width: 700px;
    //height: 540px;
    //max-height: 95%;
    height: 100%;
    background-color: white;
    border-radius: 20px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    opacity: 0;

    animation: welcome-window-anim 2s forwards;

    &__text {
      opacity: 0;
      position: relative;
      font-family: 'geomanistblack', 'Lato', sans-serif;
      font-size: 2.5rem;
      animation: welcome-text-anim 2s forwards;
    }

  }
}

@keyframes welcome-window-anim {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes welcome-text-anim {
  0% {
    opacity: 1;
    left: 300px;
    top: 20px;
    transform: scale(0.9);
  }
  30% {
    opacity: 1;
    left: 2px;
    top: 0;
    transform: scale(1);

  }
  57% {
    opacity: 1;
    left: -2px;
    top: 0;
    transform: scale(1);

  }
  100% {
    opacity: 0;
    left: -300px;
    top: 20px;
    transform: scale(0.9);

  }
}