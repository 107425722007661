
.rating-component {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &--white {
    transition: all 0.3s;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    margin: 30px 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    @include sm-only {
      margin: 20px 8px;
    }

    & img {
      opacity: 0.3;
    }

  }


}

.rating-component--selected {
  & img {
    opacity: 1;
  }

}

.rating--component--btn--img {

  width: 36px;
  height: 36px;
}

.rating-component--textarea {
  height: 110px;
  width: 95%;
  max-width: 450px;
  margin: 20px auto;
  border-radius: 20px;
}

.rating--component--center {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

