
.label {
  padding: 5px 9px;
  font-size: 0.8rem;
  font-weight: bolder;
  border-radius: 8px;
  margin: 7px;

  @include sm-only {
    margin: 3px;
  }
}

.label {
  &--red {
    background-color: $red;
    color: white;

  }

  &--green {
    background-color: $green;
    color: white;
  }

  &--blue {
    background-color: $link-blue;
    color: white;

  }
}