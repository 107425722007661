
.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000000;

  width: 100%;

  background-color: $dominant1;
  color: $dominant1text;

  padding: 30px;

  margin: auto;

  &__btn {
    margin: 5px;
    background: white;
    border: none;
    color: $blackish8;
    font-family: 'geomanistbold', 'Lato', sans-serif;
    padding: 7px 12px;
    font-size: 0.9rem;
    border-radius: 8px;
  }
}