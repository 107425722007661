
.btn--light-grey {

  border: 1px solid #bbb;
  color: #bbb;
  background: white;

  padding: 3px;
  margin: 3px;

  border-radius: 5px;

  font-size: 0.86rem;

  text-align: center;

  font-weight: bolder;

  &:hover {
    border: 1px solid #888;
    color: #888;
  }

}