/**
 * Part 1: Navbar button
 */
.navbar__notifications-container {

  &__bell {

    position: relative;
    //background-color: blue;
    border: none;
    background-color: transparent;
    width: 34px;
    height: 34px;
    //border-radius: 17px;

    & img {
      width: 32px;
      height: 32px;
      transform: rotateZ(20deg);
      opacity: 0.9;
    }

    &__red-dot {
      font-family: 'geomanistbold', 'Lato', sans-serif;
      font-size: 0.6rem !important;
      color: white !important;
      position: absolute;
      width: 23px;
      height: 23px;
      top: -7px;
      left: -3px;
      border-radius: 23px;
      background-color: #f24726;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

  }

  &:hover {
    .navbar__notifications-container__window {
      visibility: visible !important;
      transition: all .4s;
      top: 35px;
      opacity: 1;
    }
  }

  &__window {

    visibility: hidden;

    position: fixed;
    top: 0;
    right: 18px;

    opacity: 0.3;

    background-color: white;
    border-radius: 15px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.19);

    padding: 15px 20px;

    width: 95%;
    max-width: 800px;
    height: 500px;

    overflow: hidden;

    &__notifications {
      //background-color: $colors-V2-layout-grey;
      border-radius: 15px;
      //padding: 10px 15px;
      height: 360px;
      overflow-y: scroll;

      &__line {

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        &:hover {
          background-color: $grey;
        }

        &__text {
          font-size: 0.8rem !important;
          margin: 5px;
          color: black !important;

          &--selected {
            font-weight: bold;
          }
        }

      }
    }
  }
}

.navbar__notifications__title {
  font-family: 'geomanistbold', 'Lato', sans-serif;
  font-size: 2rem !important;
  color: $blackish6 !important;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.navbar__notifications__button {
  font-family: 'geomanistbold', 'Lato', sans-serif;
  border-radius: 20px;
  margin: 10px;
  padding: 8px 15px;
  border: none;
  background-color: #888;
  font-size: 0.8rem !important;
  transition: all 0.3s;
  color: white !important;

  &:hover {
    background-color: lighten(#44c5f3, 8%);
  }
}

.navbar__notifications__label {
  font-size: 0.6rem !important;
  color: black !important;
  padding: 4px 5px;
  border-radius: 6px;
  margin: 0 5px;

  &--date {
    background-color: #ddd;
  }
}


.nav-bar__notifications__small-btn {
  font-size: 0.75rem !important;
  border-radius: 20px;
  margin: 0 5px;
  padding: 4px 9px;
  min-width: 90px;
  border: none;
  background-color: #44c5f3;
  transition: all 0.3s;
  color: black !important;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: lighten(#44c5f3, 8%);
  }
}

  /**
   * Part 2: Platform
   */


.mission__new-candidate {
  font-size: 1.5rem !important;
  color: #f24726 !important;
  font-family: 'geomanistbold', 'Lato', sans-serif;
}

.kanban__new-candidate {
  position: relative;
  top: -23px;
  left: 45px;
  margin: 2px;
  padding: 2px;
  width: 55px;
  height: 20px;
  font-size: 0.6rem;
  background-color: #f24726;
  border-radius: 20px;
  cursor: pointer;
  animation: fade-in 0.3s;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'geomanistbold', 'Lato', sans-serif;

}

.kanban__new-label {
  position: absolute;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}