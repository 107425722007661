*:focus {
  outline: none !important;
}

.subnavbar-new {

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  &__item {

    margin-right: 15px;
    margin-left: 15px;
    position: relative;

    @include sm-only {
      margin-right: 0;
      margin-left: 0;
    }

    & span {
      color: $blackish4;

      opacity: 0.8;
      font-family: 'geomanistbold', 'Lato', sans-serif;
      font-size: 1rem;
      transition: all 0.2s;
    }

    &:hover {
      & span {
        color: $yellow;
      }
    }

    &--active {
      & span {
        //color: darken($dominant1, 10%);
      }

    }

  }

}

.subnavbar-new__item--decorated {

  @include from-lg {

    &::before {
      transition: all 0.2s;
      content: '';
      position: absolute;
      top: 23px;
      background-color: #bbb;
      padding: 2.6px 0%;
      border-radius: 6px;
      opacity: 0;
    }

  }
}

.subnavbar-new__item--active {

  @include from-lg {

    &::before {
      background-color: $yellow;
      padding: 2.6px 30%;
      border-radius: 2px;
      opacity: 1;
    }

  }
}