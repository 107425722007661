.business-page-jobs {

  &__container {

    width: 95%;
    max-width: 1000px;

    margin: auto;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;

    & > * {
      flex-grow: 1;
    }

    opacity: 0;
    position: relative;
    animation: assistant-appear 0.3s forwards;

    &--column {

      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;

      & > * {
        width: 100%;
      }
    }

    & button {

      background: $yellow;
      border: none;
      padding: 10px 18px;
      min-height: 60px;
      border-radius: 30px;
      transition: all 0.2s;

      margin: 5px;

      &:hover {
        background: lighten($yellow, 20%);
      }

      &:disabled {
        opacity: 0.5;
      }

      flex-grow: 1;

    }
  }
}