
.research__header {
  opacity: 0.7;
  transition: opacity 0.4s;

  &--hide {
    opacity: 0;
  }
}

/**
  Global research
*/

.research-form {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.research-input-v2 {

  width: 95%;
  max-width: 700px;
  border: 1px solid #ddd;
  height: 66px;
  border-radius: 33px;
  margin: 10px auto;
  text-align: left;
  padding: 8px 30px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &__research {
    flex-grow: 1;
    text-align: left !important;
    border: none !important;
    &:disabled {
      opacity: 0.4;
    }
  }

  &__buttons {
    flex-grow: 0;
    flex-shrink: 0;

    &__button {

      background-color: #bbb;
      border: none;
      padding: 5px 12px;
      font-family: 'geomanistbold', 'Lato', sans-serif;
      color: white;
      font-size: 0.8rem;
      margin: 3px;
      transition: all 0.3s;
      border-radius: 20px;

      &:disabled {
        opacity: 0.4;
      }

      &:hover {
        background-color: $yellow;
      }

      &--selected {
        background-color: $yellow;
      }
    }
  }
}

.navbar-research {

  box-sizing: content-box;

  margin-left: 19px;
  border: 1px solid #bbb;
  width: 220px !important;
  height: 44px !important;
  border-radius: 22px !important;
  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &__research {

    flex-grow: 1;
    flex-shrink: 1;
    border: none !important;
    text-align: left !important;
    font-size: 0.82rem !important;
    //max-width: 130px !important;
  }
  &__buttons {

    flex-grow: 0;
    flex-shrink: 0;

    &__button {

      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 10px;
      font-size: 0.82rem;
      background-color: #bbb;
      border: none;
      padding: 4px 5px;
      font-family: 'geomanistbold', 'Lato', sans-serif;
      color: white;
      transition: all 0.3s;
      border-radius: 20px;

      &:disabled {
        opacity: 0.4;
      }

      &:hover {
        background-color: $yellow;
      }

      &--selected {
        background-color: $yellow;
      }
    }
  }
}