
.landing-page__section--2 {

  padding: 90px 0;
  max-width: 100%;
  overflow: hidden;

  &__title {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    //color: $grey;
    color: desaturate(lighten($cyan, 38%), 4%);
    font-size: 9rem;
    line-height: 1.1;
    @include md-only {
      font-size: 8rem;
    }
    @include sm-only {
      font-size: 3.2rem;
    }
  }

  &__subTitle {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    color: $blackish8;
    font-size: 4rem;
    line-height: 1;

    @include md-only {
      font-size: 3rem;
    }
    @include sm-only {
      font-size: 2rem;
    }

    &--offset {
      position: relative;
      top: -50px;
      @include md-only {
        top: -50px;
      }
      @include sm-only {
        top: -30px;
      }
    }

  }

  &__text, &__text * {
    font-size: 1.6rem;
    width: 90%;
    max-width: 800px;
    margin: auto;
    @include sm-only {
      font-size: 1.2rem;
    }
  }

}



.landing-page__subsection {

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-start;

  width: 100%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;

  @include sm-only {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__article {
    width: 90%;
    max-width: 700px;
    padding: 30px;

    @include sm-only {
      padding: 5px;
    }

    &:first-child {
      @include sm-only {
        margin-bottom: 40px;
      }
    }
  }

}

.landing-page__view {

  margin: auto;
  width: 98%;
  max-width: 800px;
  overflow: hidden;

  border-radius: 20px;

  border: 4px solid $blackish8;

  & img {
    width: 100%;
  }
}

.landing-page__view--section {
  padding: 10px 0;
  max-width: 100%;
  overflow: hidden;
}