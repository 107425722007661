
//$bp-btn-color: $green;

$bp-btn-color: $cyan;

.btn-bp-main {
  padding: 7px 12px;
  background: $bp-btn-color;
  border: 1px solid $bp-btn-color;
  margin: 8px;
  color: black;
  transition: all 0.2s;
  box-shadow: none;
  font-size: 0.9rem;
  &:hover {
    box-shadow: 0 1px 10px 0 $bp-btn-color;
    //background: lighten($bp-btn-color, 10%);
  }

  @include sm-only {
    padding: 4px 6px;
    font-size: 0.9rem;
  }
}

.btn-bp-secondary {
  padding: 6px 12px;
  margin: 8px;
  border: 1px solid $blackish8;
  color: $blackish8;
  background: none;
  transition: all 0.2s;
  box-shadow: none;
  &:hover {
    box-shadow: 0 1px 5px 0 $blackish8;
    //background: lighten($green, 10%);
  }
}