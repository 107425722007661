
.research-steps {

  width: 100%;

  height: 5px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;

  overflow: hidden;

  transition: all 0.3s;

  opacity: 1;

  border-radius: 0;

  &--hidden {
    opacity: 0;
  }

  //& {
  //  visibility: hidden;
  //  color: white;
  //  font-weight: bold;
  //}

  &__step {
    width: 100%;
    flex-grow: 1;
    background-color: lighten($dominant1, 40%);

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    &__number {
      visibility: hidden;
      font-family: 'geomanistblack', 'Lato', sans-serif;

      width: 32px;
      height: 32px;
      margin: 10px auto;
      border-radius: 50%;
      color: $dominant1;
      font-weight: bolder;
      font-size: 1.12rem;

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      opacity: 0.5;

      background-color: white;
    }

    &__text {
      visibility: hidden;
      color: white;
      font-weight: bold;
      opacity: 0.5;
      font-size: 0.9rem;
    }

    &--active {
      background-color: $dominant1;

      & div {
        opacity: 1;
      }

      // {
      //  opacity: 1;
      //}
    }

  }

  &:hover {
    height: 100px;
    border-radius: 15px;
    //& div {
    //  visibility: visible;
    //  color: white;
    //}
    //.research__steps__step__number {
    //  visibility: visible;
    //}
    //.research__steps__step__text {
    //  visibility: visible;
    //}

    & div {
      visibility: visible;
    }
  }

}

.research-steps__drop-down-picture {
  width: 12px;
  height: 12px;
  opacity: 0.5;
  transform: rotateZ(90deg);
}