
$assistant--v2-width: 600px;

.assistant--v2__splash {

  &__title {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    color: $blackish8;
    font-size: 1.1rem;
  }

  &__description {
    font-size: 0.93rem;
    color: #aaa;
  }

  &__time {
    font-size: 1.05rem;
  }
}

.btn-assistant-main {

  background: $yellow;
  border: none;
  padding: 10px 18px;
  min-height: 50px;
  border-radius: 25px;
  transition: all 0.2s;

  margin: 5px;

  &:hover {
    background: lighten($yellow, 20%);
  }

  &:disabled {
    opacity: 0.5;
  }

  &--flex {
    flex: 1;
  }

}

/**
  Normal container that addapts to all kind of buttons it contains
*/
.assistant--v2-container {

  width: 95%;
  max-width: $assistant--v2-width;

  margin: auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  & > * {
    flex-grow: 1;
  }

  opacity: 0;
  position: relative;
  animation: assistant-appear 0.3s forwards;

  &--column {

    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;

    & > * {
      width: 100%;
    }
  }

}

/**
  Normal container with no flex (good for inputs)
*/
.assistant--v2-container--no-flex {

  margin: auto;
  width: 95%;
  max-width: $assistant--v2-width;
}

/**
  A container to display skills and advantages,
  a single full-width column
*/
.assistant--v2-container__label {

  position: relative;

  width: 95%;
  max-width: 600px;
  background: #e7e7e7;
  border: none;
  padding: 15px 48px 15px 22px;
  min-height: 50px;
  border-radius: 25px;
  transition: all 0.2s;

  overflow: hidden;

  margin: 5px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  animation: assistant-appear 0.3s forwards;

  &--color1 {
    background: #ffe0a8;
  }

  &--color2 {
    background: #ffbd43;
  }

  &__buttons {
    position: absolute;
    right: 20px
  }

}

/**
  Animation
*/
.assistant--v2--appear {

  opacity: 0;
  position: relative;
  animation: assistant-appear 0.3s forwards;
}
@keyframes assistant-appear {

  0% { opacity: 0; top: 30px; }
  100% { opacity: 1; top: 0; }
}


.assistant--v2-title {

  margin: auto;
  width: 95%;
  max-width: $assistant--v2-width;
  text-align: center;
  font-size: 1.1rem;
}

.assistant--v2-input {

  box-sizing: border-box;
  width: 95% !important;
  max-width: 300px !important;
  height: 50px !important;
  border-radius: 25px !important;
  padding: 0 25px !important;
  text-align: left !important;
  &--full-width {
    max-width: 600px !important;
  }
  &--center {
    text-align: center !important;
  }
}
