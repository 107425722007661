.div--center{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.kanban-options__container {

  background-color: darken($colors-V2-layout-grey, 0.3%);
  height: 49px;
  width:95%;
  max-width:400px;
  margin: 5px;
  border-radius: 30px;
  font-size: 0.9rem;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;

}
.kanban-options__buttons{
  position: absolute;
  left:5px;
  top:3px;
}
