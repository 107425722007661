.mail-component__btns{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.mail-component__import-btn{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.mail-component__save-btn{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  &button{
    height: 35px !important;
    width:35px !important;
  }
}

.mail-component__input {

  width: 220px !important;
  height: 35px !important;
  border-radius: 22px !important;
  overflow: hidden;
  border: none !important;
  text-align: left !important;
  font-size: 0.82rem !important;


}

.mail-component__select {

  width: 220px !important;
  height: 35px !important;
  border-radius: 22px !important;
  overflow: hidden;
  border: none !important;
  text-align: left !important;
  font-size: 0.82rem !important;


}