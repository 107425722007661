
.new-free-candidates {

  width: 95%;
  max-width: 800px;
  margin: 20px auto 35px auto;

  border: 1px solid #bbb;

  padding: 20px 30px;

}

.free-candidacy {

  margin: 20px auto;
  padding: 12px 20px;

  box-shadow: 0 2px 3px 1px rgba(0,0,0,0.39);


}
