/**
* Defines margins and colors for the page layout
*/
.page-layout {

  //padding-top: 85px;
  padding-bottom: 60px;

  &--grey {

    background-color: $colors-V2-layout-grey !important;
  }

  &--small {

    padding-top: 20px;

    @include sm-only {

      padding-top: 20px;
    }
  }

  @include sm-only {

    padding-top: 20px;
  }

}

/**
* Defines header style
*/
.page-layout__header {

  margin-top: 65px;

  position: relative;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;

  height: 350px;

  transition: all 2s;

  @include sm-only {
    height: 450px;
  }

  &--hidden {

    height: 0;
    //height: 0px;
    //* {
    //  visibility: hidden;
    //}
    animation: animation-header--close 1s forwards;

    @include sm-only {
      height: 0;
    }

  }

}

@keyframes animation-header--close {
  0% {
    height: 350px;
    min-height: 350px;
    @include sm-only {
      height: 450px;
      min-height: 450px;
    }
  }
  100% {
    height: 0;
    min-height: 0;
  }
}

.page-layout__header-background {
  //z-index: -1;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.page-layout__header-background--empty {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #02aab0, #00cdac); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #314755, #26a0da); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #2193b0, #6dd5ed); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.page-layout__header-background__editor {

}

.page-layout__header-mail-unverified {
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 15px;
  background-color: orange;
  padding: 10px 20px;

  @include sm-only {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    margin-top: 20px;
    text-align: center;
    border-radius: 0;
  }
}

.page-layout__header__main-content--recruiter {

  position: absolute;

  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  &__title {

    font-family: 'geomanistblack', 'Lato', sans-serif;
    color: #090909;
    font-size: 2rem;
    letter-spacing: 2px;
  }

  &__team {
    font-size: 1.3rem;
    margin-top: 10px;
    font-weight: bolder;
  }

  &__big-title {
    z-index: 998;
    position: absolute;
    bottom: 0;
    font-family: 'geomanistblack', 'Lato', sans-serif;
    color: white;
    font-size: 12rem;
    letter-spacing: 1.5px;
    opacity: 0.2;
    @include sm-only {
      font-size: 6rem;
    }
  }

  &__subtitle {
    z-index: 999;
    color: black;
  }

}

.page-layout__header-background-editor {
  position: absolute;
  bottom: 20px;
  right: 20px;
  animation: fade-in 0.45s;
}

.page-layout__header__avatar {

  position: absolute;

  top: 0;
  left: 0;

  height: 100%;
  width: 80%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  & img {
    object-fit: cover;
  }

  padding: 0 30px;

  overflow: hidden;

  &__big-avatar {

    position: relative;

    z-index: 1000;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    background-color: black;
    overflow: hidden;

    &__img {
      width: 100%;
      height: 100%;
    }
  }

  &__small-avatar {

    position: relative;

    z-index: 1001;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: black;
    overflow: hidden;
    position: relative;
    top: 60px;
    right: 60px;

    &__img {
      width: 100%;
      height: 100%;
    }
  }

}

.page-layout__header__avatar__editor {

  position: absolute;
  top: 0;
  right: 0;

  animation: fade-in 0.45s;

  z-index: 1005;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: #EEEEEE99;

}

.header__upload-image__button {

  //background-color: #f5f5f5;
  background-color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.38);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

}

.page-title {

  position: relative;

  text-align: center;

  font-family: 'geomanistblack', 'Lato', sans-serif;
  color: $blackish6;
  font-size: 3.6rem;
  letter-spacing: 1px;
  line-height: 1;

  &--smaller {
    font-size: 2rem;
  }

  @include sm-only {
    //top: 0px;
    font-size: 3rem;
  }
}

.white-container {

  background-color: white;
  border-radius: 15px;
  padding: 40px 60px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.19);

  transition: all 0.3s;

  @include sm-only {
    padding: 14px 20px;
    border-radius: 0;
    //box-shadow: none;
  }

  &--grey {
    background-color: $colors-V2-layout-grey;
    box-shadow: none;
  }

  &--white {
    box-shadow: none;
  }

}