

.business-page__avatars {

  position: absolute;

  right: 5px;
  top: 15vh;
  height: 70vh;
  //width: 80px;

  //background-color: red;

  z-index: 9999;

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-end;

  @include sm-only {
    position: relative;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;

    height: auto;
    width: 95%;

    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
  }


  &__avatar {

    position: relative;
    top: 0;
    right: 0;

    transition: all 0.2s;

    opacity: 0;
    animation: team-avatar-animation 0.4s forwards;

    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin: 5px;
    border: 3px solid $grey;

    object-fit: cover;

    $animation-delay-var: 0.09s;

    &:nth-child(1) {
      animation-delay: 1 * $animation-delay-var;
    }
    &:nth-child(2) {
      animation-delay: 2 * $animation-delay-var;
    }
    &:nth-child(3) {
      animation-delay: 3 * $animation-delay-var;
    }
    &:nth-child(4) {
      animation-delay: 4 * $animation-delay-var;
    }
    &:nth-child(5) {
      animation-delay: 5 * $animation-delay-var;
    }
    &:nth-child(6) {
      animation-delay: 6 * $animation-delay-var;
    }
    &:nth-child(7) {
      animation-delay: 7 * $animation-delay-var;
    }
    &:nth-child(8) {
      animation-delay: 8 * $animation-delay-var;
    }
    &:nth-child(9) {
      animation-delay: 9 * $animation-delay-var;
    }
    &:nth-child(10) {
      animation-delay: 10 * $animation-delay-var;
    }

    &:hover {
      right: 5px;
    }

    @include sm-only {
      width: 40px;
      height: 40px;
    }
  }
}

@keyframes team-avatar-animation {
  0% {
    opacity: 0;
    top: 20px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}