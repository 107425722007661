
.trigram-propositions {

  margin: 15px auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  & > * {
    flex-grow: 1;
  }
}

.trigram__comment {
  //position: fixed;
  //bottom: 0;
  //left: 0;
  width: 100%;
  padding: 30px;

  background-color: white;
}

.window--candidate-history {

  position: relative;
  //height : 100%;
  //max-height: 600px;
  //overflow: hidden;
  //overflow-y: scroll;
}

.trigram__li {
  padding: 25px !important;
}