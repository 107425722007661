
.btn--info {
  background: none;
  width: 26px;
  height: 26px;
  border-radius: 14px;
  border: 4px solid #ccc;
  margin: 2px 5px;

  display: inline;

  & span {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-family: 'Pacifico', 'Lato', sans-serif;
    font-size: 1.1rem;
    color: #bbb;
    position: relative;
    top: -10px;
  }

  transition: all 0.3s;

  &:hover {
    border: 4px solid #aaa;
    color: #aaa;
  }


}