
.landing-page-section-spacing {
  position: relative;
  padding: 130px 0 45px 0;
  //background-color: red;
  width: 100%;
  overflow: hidden;
  @include sm-only {
    padding: 40px 0 40px 0;
  }

  &--small {
    position: relative;
    padding: 40px 0 30px 0;
    width: 100%;
    overflow: hidden;
  }
}

//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

.landing-page-section--process {

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;

  @include sm-only {

  }

  &__container {
    //background-color: #3dbed0;
    width: 100%;
    max-width: 1280px;
    margin: auto;

    min-height: 500px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;

    //position: relative;

    @include sm-to-md {
      align-items: center;
    }

    &__box {
      position: relative;
      padding: 20px;
      margin-right: 60px;
      //background-color: green;
      max-width: 650px;
      @include sm-to-md {
        width: 100%;
        padding: 15px;
      }
      @include sm-only {
        margin-right: auto;
      }
    }
  }
}

//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

.landing-page-section--privacy {

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;

  @include sm-only {

  }

  &__container {
    //background-color: #3dbed0;
    width: 100%;
    max-width: 1280px;
    margin: auto;

    min-height: 500px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;

    //position: relative;

    @include sm-to-md {
      align-items: center;
    }

    &__box {
      position: relative;
      padding: 20px;
      margin-right: 60px;
      //background-color: green;
      max-width: 600px;
      @include sm-to-md {
        width: 100%;
        padding: 15px;
      }
      @include sm-only {
        margin-right: auto;
      }
    }
  }
}

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

.process {
  &__square {
    position: absolute;
    width: 70%;
    height: 70%;
    left: 50px;
    background-color: lighten($violet, 30%);
    border-radius: 20px;
    @include sm-only {
      width: 100%;
      top: 100px;
      left: -200px;
    }
  }
  &__figure {
    position: absolute;

    background-image: url("https://s3.eu-west-3.amazonaws.com/myshortlist.co-public/img-rocket--violet.svg");
    background-size: cover;

    width: 200px;
    height: 200px;

    @include sm-only{
      width: 150px;
      height: 150px;
      top: 40px;
      left: 30px;
    }
    @include md-only {
      top: 100px;
      left: 100px;
    }
    @include from-lg {
      width: 300px;
      height: 300px;
      top: 100px;
      left: 200px;
      right: 18%;
      max-width: 400px;
    }
  }
}

/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////


.privacy {
  &__square {
    position: absolute;
    width: 70%;
    height: 70%;
    left: 50px;
    background-color: lighten($green, 30%);
    //transform: rotateZ(-12deg);
    border-radius: 20px;
    @include sm-only {
      width: 80%;
      top: 100px;
      left: -50px;
    }
  }
  &__figure {
    position: absolute;

    background-image: url("https://s3.eu-west-3.amazonaws.com/myshortlist.co-public/lise-splash.png");
    background-size: cover;

    bottom: 40px;

    @include sm-only{
      display: none;
    }
    @include md-only {
      left: 100px;
    }
    @include from-lg {
      //width: 300px;
      height: 90%;
      left: 200px;
      right: 18%;
      max-width: 400px;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

.landing-page-section__title, .landing-page-section__title * {
  font-family: 'geomanistblack', 'Lato', sans-serif;
  position: relative;
  font-size: 2.3rem;
  font-weight: 900;
  width: 100%;
  //text-align: left;
  margin-bottom: 30px;
  @include sm-only {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 800;
    margin-bottom: 20px;
  }
}

.landing-page-section__text {
  text-align: left;
  position: relative;
  //background-color: yellow;
  //&--offset {
    right: -40px;
    @include sm-only {
      text-align: center;
      right: 0;
    }
  //}

}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////

.landing-page-section--why {

  margin: 0px 0 10px 0;

  & img {
    width: 160px;
    height: 160px;
  }

  &__articles {

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;

    margin: 45px 0 65px 0;

    @include sm-only {
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    &__article {
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      width: 295px;
      padding: 0 25px;

      @include sm-only {
        padding: 40px 25px;
      }

      &__title {

      }

      &__text {
        text-align: center;
        font-size: 0.9rem;
      }
    }
  }

}
