
.btn-import-export-xls-csv {

  width: 90px;
  height: 90px;

  border-radius: 8px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  border: 1px solid grey;
  background-color: white;

  margin: 10px;

  & img {
    transition: all 0.2s;
    width: 70px;
    height: 70px;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    //background-color: $grey;
    & img {
      width: 85px;
      height: 85px;
    }
  }

}