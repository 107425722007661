
.business-page--v2__logo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  &__content {
    padding-left: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    &__title {
      font-family: 'geomanistbold', 'Lato', sans-serif;
      color: black;
      font-size: 0.95rem;
    }

    &__subtitle {
      color: #aaa;
      font-size: 0.8rem;
    }
  }
}

.business-page--V2__navbar {

  z-index: 1000000;

  width: 100%;
  height: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  padding: 20px 100px 20px 0;

  background-color: rgba(226, 232, 234, 0.93);

  @include sm-only {
    z-index: 100000000;
    padding: 0;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: white;
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.38);
    justify-content: center;
    align-items: center;
  }

  &__ul {
    z-index: 100000001;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    @include sm-only {
      justify-content: center;
      flex: 1;
    }
  }

  &__li {

    position: relative;
    z-index: 100000002;

    margin: 0 20px;

    &--selected {

      font-weight: bolder;
      position: relative;

      &::after {
        @include from-md {
          padding: 3px 0;
          content: '';
          position: absolute;
          left: 0;
          top: 25px;
          width: 100%;
          background-color: $yellow;
          border-radius: 3px;
          transition: all .3s;
          animation: fade-in 0.2s forwards;
        }

        @include sm-only {
          padding: 5px 0;
          content: '';
          position: absolute;
          left: 0;
          bottom: 0px;
          width: 100%;
          background-color: $yellow;
          border-radius: 3px;
          transition: all .3s;
          animation: fade-in 0.2s forwards;
        }
      }

      & div {
        font-weight: bolder;
        @include sm-only {
          flex:1;
          width: 100%;
        }
      }
    }

    &--disabled {
      opacity: 0.5;
    }

    @include sm-only {

      z-index: 100000002;

      font-size: 0.9rem;

      margin: auto;
      flex: 1;

      height: 70px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: stretch;
      align-items: center;
    }

    &__btn {

      transition: all 0.2s;

      @include sm-only {
        width: 50vw !important;
        flex:1 !important;
      }

      &:disabled {
        opacity: 0.5;
      }

      * {
        font-size: 1.11rem;
      }

      @include sm-only {

        width: 100%;
        height: 70px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
      }
    }
  }

}