
.button--next {

  position: relative;
  top: -5px;

  background-color: transparent;
  border: none;

  margin: 20px;

  & img {
    width: 20px;
    height: 20px;
  }
}

.button--previous {
  transform: rotateZ(180deg);
}

.btn--upper{
  position: relative;
  top: -100px;
}

.button--linkedin {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  font-family: 'geomanistbold', 'Lato', sans-serif;
  //font-weight: 900;
  font-size: 1.4rem;

  transition: all 0.3s;

  margin: 0px;

  background-color: $linkedin;
  background-image: linear-gradient(to top, $linkedin, lighten($linkedin, 30%));
  color: white;

  width: 30px;
  height: 30px;
  border-radius: 5px;

  & img {
    width: 100%;
    height: 100%;
  }
}

.candidate-evaluate__container {

  position: relative;

  width: 95%;
  max-width: 890px;

  @include sm-only {
    heigth: auto;
  }
  border-radius: 30px;
  background-color: white;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid white;

  @include sm-only {
    padding: 15px 12px;
  }
}

.header {

  height: 280px;

  &__avatar-container {

    background-color: white;
    width: 240px;
    height: 240px;
    border-radius: 120px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & img {
      object-fit: cover;
    }
  }

  &__avatar {

    width: 190px;
    height: 190px;
    border-radius: 95px;
    object-fit: cover;
  }
}

.header--img {
  background-color: white;
  height: 200px;
  width: 200px;
}

.header__name {
  font-family: 'geomanistbold', 'Lato', sans-serif;
  font-size: 1.2rem;
  margin: 0 auto 10px auto;
}


.evaluation {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

}

.evaluation--button {
  border: none;
  background-color: transparent;
  margin: 20px;
  & img {
    transition: opacity, transform 0.2s;
    opacity: 0.5;
  }
  &--selected {
    & img {
      opacity: 1;
    }
  }
  &:hover {
    & img {
      transform: scale(1.1);
    }
  }
}

.evaluation--btn--img{
  height: 45px;
  width: 45px;
}

.evaluation__comment {
  width: 95%;
  max-width: 520px;
  height: 200px;
  border-radius: 18px;
  text-align: left;
  padding: 20px 35px;
}

.job--details {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.header--up {
  position: relative;
  top: -130px;
}
