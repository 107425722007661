

.tag-color-choice {
  width: 30px;
  height: 30px;

  border-radius: 6px;

  margin: 3px;

  border: 2px solid grey;

  &--selected {
    border: 3px solid black;
  }
}


.tag--0 {
  background-color: #f2eded;
  color: $blackish6;
}

.tag--1 {
  background-color: #7eceec;
  color: $blackish6;
}

.tag--2 {
  background-color: #c2e2c1;
  color: $blackish6;
}

.tag--3 {
  background-color: #f09767;
  color: $blackish6;
}

.tag--4 {
  background-color: #ea7269;
  color: $blackish6;
}

.tag--5 {
  background-color: #ee98b0;
  color: $blackish6;
}

.tag--6 {
  background-color: #fcf768;
  color: $blackish6;
}

.tag--7 {
  background-color: #a647b7;
  color: $blackish6;
}

.tag--8 {
  background-color: #242424;
  color: white;
}



.tags-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;


  &__tag {
    position: relative;
    top: 0;
    flex: 1;
    margin: 2px;
    padding: 3px 8px;
    border-radius: 4px;
    border: 1px solid #bbb;
    font-family: 'geomanistbold', 'Lato', sans-serif;
    font-size:0.97rem;
    transition: all 0.18s;
    opacity: 1;
    &:hover {
      cursor: pointer;
      top: -4px;
    }

    &--hollow {
      opacity: 0.4;
    }
  }

}

.tag-on-profile {
  margin: 2px;
  padding: 3px 8px;
  border-radius: 4px;
  border: 1px solid #bbb;
  font-family: 'geomanistbold', 'Lato', sans-serif;
  font-size:0.97rem;
  transition: all 0.18s;
  opacity: 1;
}