
.medal {
  width: 22px;
  height: 22px;

  border-radius: 50%;
  margin: 3px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: $yellow;

  color: white;
  font-size: 1.2rem;
  font-family: 'geomanistbold', 'Lato', sans-serif;


  position: relative;
  //top: 12px;
  //left: 8px;
  top: 12px;
  right: 20px;

  &--grey {
    background-color: #666;
  }
}