
.link-box2 {

  background-color: $colors-V2-layout-grey;
  padding: 5px 30px;
  min-height: 60px;
  border-radius: 30px;
  width: 90%;
  max-width: 700px;
  margin: auto;

  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  color: #666;

  &--white {
    background-color: white;
  }

  &--left {
    justify-content: flex-start;
  }
}

.invisible {
  opacity: 0;
}

.height-fixed {
  height: 40px;
}