
.label-documents {

  width: 90%;

  margin: 20px auto;
  padding: 12px 20px;

  box-shadow: 0 2px 3px 1px rgba(0,0,0,0.39);

}