
.trigram {

  width: 95%;
  margin: 20px auto;
  padding: 12px 20px;

  box-shadow: 0 2px 3px 1px rgba(0,0,0,0.39);

  &__header {

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

  }

  &__title {

    margin: 10px 0;
  }
  //
  //&:nth-child(odd) {
  //  background-color: lighten($dominant1, 40%);
  //}


}