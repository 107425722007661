
.video {
  padding: 50px 0;
  margin: 70px 0;

  background-color: $grey;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

}

.video-warning {
  padding: 20px;
  margin: 20px auto;
  border-radius: 18px;
  width: 98%;
  max-width: 600px;
  background-color: #ff9e6d;
}