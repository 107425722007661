
.full-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  &--relative {
    position: relative;
  }
}