
.landing-page__comments {

  padding: 160px 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  @include sm-only {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__comment {

    padding: 28px;

    text-align: center;

    width: 100%;
    max-width: 560px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;


    @include sm-only {
      margin: 40px auto;
    }

    * {
      font-size: 1.8rem;
      @include sm-only {
        font-size: 1.2rem;
      }
    }

    & div:first-child {
      margin-bottom: 18px;
    }

    & div:last-child {
      font-size: 1.3rem;
      @include sm-only {
        font-size: 1.1rem;
      }
    }

  }

}

.landing-page__comments__buttons {

  & button {
    border: none;
    background-color: $grey;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin: 10px;
    box-sizing: border-box;

    transition: all 0.3s;
  }

  & .landing-page__comments--selected {
    background-color: $cyan;
  }
}
