
.animation-fade-in {
  opacity: 0;
  animation: fade-in 0.3s forwards;
  &--faster {
    opacity: 0;
    animation: fade-in 0.2s forwards;
  }
  &--slow {
    opacity: 0;
    animation: fade-in 1s forwards;
  }
}

.animation-straight-fade-in {
  animation: fade-in 0.18s forwards;
}

@keyframes straight-fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-in {
  0% { opacity: 0; }
  30% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0;}
}