
.apply-V2__title {

  text-align: center;
  font-size: 1.2rem;
  font-family: 'geomanistbold', 'Lato', sans-serif;
}

.apply-V2__title__main {

  text-align: center;
  font-size: 1.7rem;
  font-family: 'geomanistbold', 'Lato', sans-serif;
}

.apply-V2__result {
  text-align: center;
  font-size: 7rem;
  color: $colors-V2-blue2;
  font-family: 'geomanistbold', 'Lato', sans-serif;
}

.apply-V2__result__bar {
  position: relative;
  width: 80%;
  max-width: 400px;
  margin: auto;
  height: 15px;
  background-color: #ddd;
  overflow: hidden;
}

.apply-V2__result__bar__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 15px;
  background-color: $colors-V2-blue2;
}

.apply-V2__result__bar__content {
  &--50 {
    width: 50%;
  }
  &--55 {
    width: 55%;
  }
  &--60 {
    width: 60%;
  }
  &--65 {
    width: 65%;
  }
  &--70 {
    width: 70%;
  }
  &--75 {
    width: 75%;
  }
  &--80 {
    width: 80%;
  }
  &--85 {
    width: 85%;
  }
  &--90 {
    width: 90%;
  }
  &--95 {
    width: 95%;
  }
  &--100 {
    width: 100%;
  }
}

.apply-textarea {
  width: 95%;

  height: 200px;
}