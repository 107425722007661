
.navbar-secondary {

  z-index: 11000;

  &--pc__items, &--smartphone__items {

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;

    & > * {
      flex: 1;
      text-align: center;
    }

    & li, button {

      transition: color 0.4s;

      //font-family: 'Lato', sans-serif;

      color: #777;


      & > * {
        font-size: 1.14rem;
        font-weight: 500;
      }

      &:hover {
        color: #333;
      }

      & .selected {
        color: #222;
      }

      @include sm-only {
        font-size: 1rem;
      }

    }

    & button {
      width: 100%;
    }
  }

}

.navbar-secondary--pc {

  z-index: 11000;

  background-color: white;

  //padding: 13px 50px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  align-items: stretch;

  margin: auto;

  width: 100%;
  //max-width: 1240px;

  &__container {
    width: 100%;
    max-width: 1240px;
    margin: auto;
  }

  & li {

    position: relative;

    background-color: white;

    min-height: 55px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

  }

  & .selected {

    position: relative;
    //background-color: lighten($green, 20%);
    //border-bottom: 4px solid $green;
    &::after {
      padding: 2px 0;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: darken($dominant1, 10%);
      border-radius: 3px;
      transition: all .3s;
      animation: fade-in 0.2s forwards;
    }
    color: #222;
    & * {
      color: #222;
    }
  }

  // If sticky is disabled, use this
  //margin: 50px auto 80px auto;

  &--sticky {

    margin: 50px auto 80px auto;

    width: 100%;
    padding: 22px 0 20px 0;
    background-color: white;
    position: sticky;
    top: 85px;
  }

}

.navbar-secondary--smartphone {

  z-index: 11000;

  background-color: white;

  position: fixed;
  bottom: 0;
  left: 0;
  margin: auto;

  align-items: stretch;

  width: 100%;

  //padding: 20px;

  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.18);

  & li {

    background-color: white;

    min-height: 65px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

  }

  & .selected {
    //background-color: lighten($green, 20%);
    //border-bottom: 4px solid $green;
    position: relative;
    &::after {
      @include sm-only {
        padding: 4px 0;
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: $yellow;

        border-radius: 3px;
        transition: all .3s;
        animation: fade-in 0.2s forwards;
      }
    }
  }

}

//.navbar-secondary__items--selected {
//  //font-size: 1rem;
//  background-color: red !important;
//  color: #333;
//}

.navbar-secondary-btn-image {
  width: 30px;
  height: 30px;

  &--smaller {
    width: 23px;
    height: 23px;
  }
}