

.btn-geolocalize {

  padding: 10px 23px;
  background-color: $dominant1;
  color: $dominant1text;

  border: none;

  margin: 7px;

  &:hover, &:disabled {
    background-color: lighten($dominant1, 12%);
  }

}