
.history {

  & * {
    font-size: 0.9rem;
  }

  &--animated {
    transition: all 0.5s;
  }

  &__title {
    padding: 20px 27px;
    //background-color: #dfdfdf;
    background-color: $dominant1;
    color: white;
    *{color: white}
    cursor: pointer;
    &--radius-all {
      border-radius: 20px 20px 20px 20px;
    }
    &--radius-top {
      border-radius: 20px 20px 0px 0px;
    }

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &__icon {
      width: 20px;
      height: 20px;
      margin-right: 20px;
      transition: all 0.4s;
      transform: rotateZ(0deg);
      &--active {
        transform: rotateZ(90deg);
      }
    }

  }

  &__body {
    padding: 20px 27px;
    //background-color: #eee;
    background-color: lighten($dominant1, 50%);
    color: #777;
    border-radius: 0 0 20px 20px;

    transform-origin: top left;
    animation: history-open 0.6s forwards;
    animation-delay: 0.1s;
    opacity:0.5;
    transform: scaleY(0);

    &--closing {
      animation: history-close 0.6s forwards;
      & * {
        //animation: fade-out 0.3s backwards;
        //opacity: 0;
        visibility: hidden;
      }

    }

    & * {
      opacity: 0;
      animation: fade-in 0.2s forwards;
      animation-delay: 0.33s;
    }
  }
}

@keyframes history-open {
  0% {
    opacity: 0.5;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes history-close {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0.5;
    transform: scaleY(0);
  }
}