
.trigram-v2 {


}

.trigram-emoji {
  width: 23px;
  height: 23px;
  margin: 4px;
}


.trigram-v2__specific-chat {

  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.39);
  padding: 30px 50px;

  background-color: white;

}

.trigram-v2__messages {

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;


  &__message {

    position: relative;
    width: 90%;
    min-height: 70px;
    margin: 5px auto;
    background-color: $colors-V2-layout-grey;

    padding: 8px 0 22px 0;

    border-radius: 60px;

    animation: fade-in-to-up 0.35s forwards;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    &--human {
      background-color: $colors-V2-blue2;
    }

    &__avatar {
      background-color: white;
      flex-grow: 0;
      flex-shrink: 0;
      width: 56px;
      height: 56px;
      border-radius: 28px;
      margin: 15px 15px 5px 15px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      color: #bbb;
    }

    &__body {
      flex-grow: 1;
      height: 100%;
    }

    &__body--span {
      font-size: 0.9rem;
    }

    &__header {
      font-size: 0.8rem;
      margin-bottom: 10px;
    }

  }

}

.trigram-v2__specific-chat__send-bar {

  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  position: relative;
}

.trigram-v2__specific-chat__input {
  width: 60%;
  height: 90px;
  text-align: left;
  position: relative;

  border-radius: 20px !important;

  //background-color: #bbb !important;

  font-size: 0.87rem;
}

.trigram-v2__specific-chat__send {

  //position: absolute;
  //bottom: -40px;
  //left: 10px;

  background-color: #999;
  //background-color: white;
  color: black;
  font-family: 'geomanistbold', 'Lato', sans-serif;
  width: 90px;
  height: 30px;
  border: none;
  border-radius: 15px;

  font-size: 0.9rem;

  transition: all 0.3s;

  &:disabled {

    opacity: 0.4;
  }

  &:hover {

    background-color: $yellow;
  }


}