
.team-table {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  & tr {

    //display: flex;
    //flex-flow: row nowrap;
    //justify-content: space-between;
    //align-items: center;

    //border-radius: 10px;
    border-bottom: 1px solid #bbb;
    //box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);

    transition: all 0.3s;

    overflow: hidden;

    &:hover {
      cursor: pointer;
      background-color: #eeeeee;
    }

  }

  & td {
    padding: 15px 30px;
    overflow: hidden;

  }
}

.team-table--smartphones {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;

  &__member {
    //margin: 10px 0;
    //box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    //border-radius: 10px;

    //border-top : 1px solid #bbb;
    border-bottom : 1px solid #bbb;

    padding: 10px 25px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    & article {
      margin: 17px 0;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    &__default {
      text-align: center;
    }
  }

}