/**
* Module responsible for responsiveness
*/

// Breakpoints
$bs_bp_xxs_xs : 776px;
$bs_bp_xs_sm : 900px;
$bs_bp_sm_md : 1200px;

/*
* On small devices only
*/
@mixin sm-only {
  @media (min-width: #{0px}) and (max-width: #{$bs_bp_xxs_xs - 1px}) {
    @content;
  }
}

/*
* On small to medium devices only
*/
@mixin sm-to-md {
  @media (min-width: #{0px}) and (max-width: #{$bs_bp_sm_md - 1px}) {
    @content;
  }
}

/*
* On medium devices only
*/
@mixin md-only {
  @media (min-width: #{$bs_bp_xxs_xs}) and (max-width: #{$bs_bp_sm_md - 1px}) {
    @content;
  }
}

/*
* On medium and big devices only
*/
@mixin from-md {
  @media (min-width: #{$bs_bp_xxs_xs}) {
    @content;
  }
}

/*
* On big devices only
*/
@mixin from-lg {
  @media (min-width: #{$bs_bp_sm_md}) {
    @content;
  }
}
