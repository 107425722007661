
.landing-page__blog {

  padding: 60px 0;

  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  @include sm-to-md {
    flex-flow: column nowrap;
  }


  &__left {
    flex: 1;
    width: 100%;
    padding: 30px;

    &__main-picture {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    &__picture-comment {
      * {
        font-size: 1rem;
        color: grey;
      }
      font-size: 0.8rem;
      text-align: center;
      color: grey;
    }
  }

  &__right {
    flex: 1;
    width: 100%;
    padding: 30px;

    & div {
      margin-bottom: 20px;
    }

    &__suptitle {
      text-align: center;
      font-family: 'geomanistbold', 'Lato', sans-serif;
      font-size: 7rem;
      color: desaturate(lighten($cyan, 38%), 4%);
    }

    &__title {
      text-align: center;
      font-family: 'geomanistbold', 'Lato', sans-serif;
      font-size: 1.6rem;
    }

    &__subtitle {
      text-align: center;
      font-size: 1.3rem;
      color: grey;
    }

    &__link {
      text-align: center;
      //& a {
      //  font-size: 1.2rem;
      //
      //}
    }

  }
}