
.profile-element__container {

  background-color: darken($colors-V2-layout-grey, 0.3%);
  padding: 10px 30px;
  min-height: 60px;
  margin: 15px;

  border-radius: 30px;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  @include sm-only {
    height: auto;
    padding: 10px 16px;
    justify-content: center;
  }
}