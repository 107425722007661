.candidates-evaluations-container {

  position: relative;
  width: 95%;
  max-width: 690px;
  height: 50px;


  border-radius: 30px;
  border: 2px solid white;
  background-color: white;
  margin: 10px auto;
  padding: 5px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  transition: all 0.2s;
  animation: fade-in 0.4s;


  @include sm-only {
    height: auto;
    flex-flow: column nowrap;
    padding: 4px;
  }


  &--selected{
    border: 2px solid #44c5f3;
  }
}

.candidates-evaluations-name-container{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin:10px;
}

.candidates-evaluations-name{
  padding:2px;
}

.candidates-evaluations-btn{

  height: 30px;
  border-radius: 20px;
  margin: 5px;
  padding: 0 20px;
  border: none;
  background-color: #44c5f3;
  transition: all 0.3s;
  color: white;

  &:hover {
    background-color: lighten(#44c5f3, 8%);
  }

  &:disabled {
    background-color: lighten(#44c5f3, 8%);
    opacity: 0.5;
  }

  &--todo {
    background-color: #f24726;
    &:hover {
      background-color: lighten(#f24726, 8%);
    }
    &:disabled {
      background-color: lighten(#f24726, 8%);
      opacity: 0.5;
    }
  }
}

.candidates-evaluations-avatar{

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  z-index: 1001;
  width: 60px;
  height: 60px;
  border-radius: 50px;

  }


.candidates-evaluations-header{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  @include sm-only{
    width:95%
  }
}

.candidates-evaluations-positionWanted{
  position: relative;
  top:3px;
}


.candidates-evaluations-research{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.candidates-evaluations-research-input{

background-color: white !important;
margin: 0 !important;
width: 250px !important;
border-radius: 70px !important;
text-align: left !important;

  &--selected{
    border: 2px solid #44c5f3 !important;
    border-radius: 70px !important;
  }

}

//.candidates-evaluations-research-input-content{
//  border:none;
//  width: 240px;
//}


.candidates-evaluations-names {
  margin: 30px 0 15px 0;

}

.candidates-evaluations-name {
  display: inline-block;
  font-size: 0.9rem;
  padding: 5px 8px;
  background-color: white;
  border-radius: 20px;
  margin: 5px
}