
.messages--v2 {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  height: 100%;

  &__message {

    overflow: hidden;

    padding: 15px;
    margin: 10px;
    border-radius: 20px;

    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.39);

    align-self: flex-end;

    width: 80%;

    background-color: #ccf1af;

    animation: fade-in 0.3s;

    &--answer {

      align-self: flex-start;
      background-color: #f8f8f8;
    }

    &__date {

      text-align: right;
      color: #666;
      font-size: 0.81rem;
    }

    &__text {
      font-size: 0.87rem !important;
    }
  }
}

.messages--v2__chat {

  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.39);
  padding: 10px 50px;

  transition: all 0.2s;

  overflow: hidden;

  //background-color: #666;
  background-color: white;

  &--extended {

    height: 230px;
  }

  &__subject {

    width: 60% !important;
    max-width: 500px !important;
    height: 15px !important;
    border-radius: 15px !important;
    border: 1px solid #bbb;

    text-align: left !important;

    //background-color: #bbb !important;
    font-size: 0.87rem !important;

  }

  &__message {

    width: 60% !important;
    max-width: 500px !important;
    height: 80px !important;
    border-radius: 15px !important;
    border: 1px solid #bbb;

    text-align: left !important;

    //background-color: #bbb !important;
    font-size: 0.87rem;

  }
}


.message--v2__chat__button {

  background-color: #bbb;
  color: black;
  font-family: 'geomanistbold', 'Lato', sans-serif;
  border: none;
  border-radius: 15px;

  padding: 8px 12px;

  font-size: 0.9rem;

  transition: all 0.3s;

  margin: 10px;

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    background-color: $yellow;
  }
}

.btn-extend {
  background-color: transparent;
  //width: 30px;
  //height: 20px;
  padding: 0 5px 3px 5px;
  //display: flex;
  //flex-flow: column nowrap;
  //justify-content: center;
  //align-items: center;
  border: 1px solid #666;
  border-radius: 15px;
  font-size: 0.8rem;
  &:hover {
    border: 1px solid #111;
  }
}