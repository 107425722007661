.missions--v2 {
  width: 95%;
  //max-width: 1300px;

  margin: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.mission--v2 {

  position: relative;

  width: 95%;
  max-width: 890px;

  //height: 170px;

  @include sm-only {
    heigth: auto;
  }

  border-radius: 30px;
  background-color: white;

  margin: 20px auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  padding: 20px;

  border: 2px solid white;

  transition: all 0.2s;

  animation: fade-in 0.4s;

  @include sm-only {
    padding: 15px 12px;
  }

  &--active-research {
    border: 2px solid $colors-V2-blue2;
  }

}

.mission--V2 {
  &--left {
    width: 130px;
    min-height: 90px;

    @include sm-only {
      width: 100px;
    }

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    border-right: 1px solid #bbb;
  }

  &--right {
    width: 100%;
    //background-color: #ee98b0;
    padding-left: 20px;

    &__line {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.mission--V2__candidacies {
  &__title {
    font-family: 'geomanistbold', 'Lato', sans-serif;
    font-size: 0.9rem;
  }

  &__number {
    font-family: 'geomanistbold', 'Lato', sans-serif;
    font-size: 2rem;
  }
}

.mission--V2 {
  &__title {
    font-family: 'geomanistbold', 'Lato', sans-serif;
    color: $colors-V2-blue2;
    font-size: 1.3rem;
    //display: flex;
    //flex-flow: row nowrap;
    //justify-content: flex-start;
    //align-items: center;
  }
  &__comment {
    margin: 8px 0;
    color: #aaa;
  }
}


.mission--V2__buttons {

      //width: 95%;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;

      &__main {
        margin: 5px;
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: 1.1rem;
    color: white;
    background-color: #bbb;
    height: 40px;
    padding: 0 15px;
    border-radius: 20px;
    border: none;
    transition: all 0.2s;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: $yellow;
    }
  }

  &__parameters {
    margin: 5px;
    //background-color: #bbb;
    background-color: white;
    opacity: 0.3;
    height: 33px;
    width: 33px;
    border-radius: 20px;
    border: none;
    transition: all 0.1s;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    &:hover {
      //background-color: $newDominant1;
      background-color: $colors-V2-blue2;
      opacity: 1;
      & img {
        filter: invert(100%);
      }
    }
    & img {
      width: 26px;
      height: 26px;
    }
    &--small-pic img {
      width: 22px;
      height: 22px;
    }
    &--white {
      &:hover {
        background-color: white;
        & img {
          filter: invert(0%);
        }
      }
    }
  }

  &__pinned {
    margin: 5px;
    //background-color: #bbb;
    background-color: white;
    opacity: 0.3;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: none;
    transition: all 0.1s;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    &:hover {
      //background-color: $newDominant1;
      opacity: 1;
    }
    &--active {
      opacity: 1;
    }
    & img {
      width: 23px;
      height: 23px;
    }
  }

}

.mission--V2__status {

  color: #bbb;
  font-weight: bolder;

  &--published {
    color: darken(#4aeed8, 10%);
  }

  &--collaboration {
    color: #ee5d4a;
  }

  &--draft {
    color: darken($colors-V2-dominant1, 10%);
  }

  &--history {
    color: #ee4a7e;
  }

  &__arrow {
    width: 10px;
    height: 10px;
    margin: 3px;
    transform: rotateZ(90deg);
    position: relative;
    top: -2px;
    opacity: 0.5;
  }

}