
.business-page__element {

  &--1--1 {
    flex-shrink: 0;
    position: relative;
    background-color: lightgray;
    border-radius: 10px;
    width: $el-single-width;
    height: $el-single-height;
    margin: $el-margin $el-margin;
    overflow: hidden;
    @include sm-to-md {
      width: 95%;
      min-height: $el-single-height*2;
    }
  }

  &--2--2 {
    flex-shrink: 0;
    position: relative;
    background-color: lightgray;
    border-radius: 10px;
    width: $el-double-width;
    height: $el-double-height;
    margin: $el-margin $el-margin;
    overflow: hidden;
    @include sm-to-md {
      width: 95%;
      margin: auto;
      height: $el-double-height + 10px;
    }
  }

  &--1--2 {
    flex-shrink: 0;
    position: relative;
    background-color: lightgray;
    border-radius: 10px;
    width: $el-single-width;
    height: $el-double-height;
    margin: $el-margin $el-margin;
    overflow: hidden;
    @include sm-to-md {
      width: 95%;
      margin: auto;
      min-height: $el-double-height*2;
    }
  }

  &--1--free {
    flex-shrink: 0;
    position: relative;
    border-radius: 10px;
    background-color: lightgray;
    width: $el-single-width;
    margin: $el-margin $el-margin;
    overflow: hidden;
    @include sm-to-md {
      width: 95%;
      margin: auto;
    }
  }

  &__last-block {

    //flex-grow: 1;
    //align-self: stretch;
    //justify-self: stretch;

    @include sm-only {
      display: none;
    }

    flex-grow: 1;

    border-radius: 10px;

    position: relative;
    background-color: $grey;
    width: $el-single-width;
    margin: $el-margin $el-margin;
    overflow: hidden;

    //border-bottom: 5px solid white;

    @include sm-to-md {
      width: 95%;
      margin: auto;
    }

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: stretch;

    &__white {
      position: relative;
      top: -15px;
      width: 100%;
      height: 15px;
      background-color: white;
    }
  }

  ///////////////////////////////////

  &__image {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__free-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;

    background-color: #1DA1F2;
    //border: 1px solid $grey;

    &__title {
      text-align: center;
      font-family: 'geomanistbold', 'Lato', sans-serif;
      font-size: 1.5rem;
      color: white;
      //margin-bottom: 20px;
    }

    &__content {
      text-align: center;
      color: white;
      font-size: 1.12rem;
    }

  }

  &__apply {

    width: 95%;
    max-width: 868px;

    background-color: white;

    margin: 0 auto 10px auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    padding: 20px;

    border: 1px solid lightgrey;
    border-radius: 10px;

    &__title {
      margin-bottom: 20px;
      color: $colors-V2-blue2;
      font-size: 1.5rem;
      font-family: 'geomanistblack', 'Lato', sans-serif;

      @include sm-only {
        margin-bottom: 11px;
      }
    }

    &__subtitle {
      margin-bottom: 20px;
      color: grey;
      font-size: 1.2rem;
      text-align: center;
      @include sm-only {
        margin-bottom: 11px;
        font-size: 1.1rem;
      }
    }

    &__link {
      margin-bottom: 20px;
    }

    & .legend {
      font-size: 0.9rem;
      color: black;
      font-weight: bolder;
    }

    & .job {
      font-family: 'geomanistblack', 'Lato', sans-serif;
      font-size: 1.5rem;
      color: $dominant1;
      text-align: center;
      @include sm-only {
        font-size: 1.4rem;
      }
    }

  }

}
