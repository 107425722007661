
.candidate-answers {

  &__question {
    padding: 5px 10px;
    background-color: $blackish8;
    color: white;
    font-size: 1.1rem;
    margin: 20px 0;
  }

  &__answer {

    padding: 7px 10px;
    border-radius: 20px;

    &--good {
      background-color: $green;
    }

    &--wrong {
      background-color: lighten($red, 30%);
    }

    &--grey {
      background-color: #ddd;
    }
  }

}