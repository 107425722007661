

.editor-new-item__screen {

  z-index: 22000000;

  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  background-color: #000d;
  //background-color: rgba(255, 255, 255, 0.81);

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  transition: background-color 0.15s;

}

.editor-new-item__choices {

  width: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  &__choice {

    //width: 150px;
    //height: 150px;
    height: 180px;
    width: 180px;
    padding: 5px;
    margin: 16px;

    color: $dominant1text;

    *{
      color: $dominant1text;
    }

    //border-radius: 8px;
    border-radius: 50%;

    //font-family: 'Lato', sans-serif;

    border: none;
    background-color: $colors-V2-blue2;
    background-image: linear-gradient(to top, $colors-V2-blue2, lighten($colors-V2-blue2, 8%));
    color: white;
    font-weight: 400;

    transition: all 0.2s;

    transform: scale(1);

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    & img {
      transition: all 0.2s;
      width: 50px;
      height: 50px;
      filter: invert(1);
      margin-top: 15px;
    }

    &:hover {
      background-image: linear-gradient(to top, lighten($dominant1, 9%), lighten($dominant1, 14%));
      transform: scale(1.05);
      & img {
        transform: scale(1.2);
      }
    }

    &:disabled {
      background-image: linear-gradient(to top, lighten($dominant1, 19%), lighten($dominant1, 24%));
      transform: scale(1.05);
      opacity:0.9;
      & img {
        transform: scale(1.2);
      }
    }

    @include sm-only {
      margin: 10px;
    }

  }
}

.editor-new-item__window {
  width: 93%;
  max-width: 650px;
  max-height: 90vh;

  background-color: white;

  padding: 37px 50px 50px 50px;

  overflow-y: auto;
  overflow-x: hidden;

  //opacity: 0;
  //animation: getsRounded 0.6s forwards;
  //animation-delay: 0.3s;

  @include sm-only {
    padding: 37px 30px 50px 30px;
  }

}


.editor-new-item__window {

  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;

    &__logo {
      font-family: 'Pacifico', cursive;
      font-size: 1rem;
      //font-weight: bold;
      color: $dominant1;
    }
  }

  &__title {
    text-align: left;
    margin: 20px auto 15px auto;
    font-weight: 500;
    font-size: 1.3rem;
    color: #444;
  }

  &__comment {
    text-align: left;
    font-weight: 500;
    font-size: 0.9rem;
    color: #444;
  }

  &__preview-box {
    border: 1px dashed #555;
    padding: 20px;
    font-size: 0.8rem;

    min-height: 120px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    @include sm-only {
      padding: 12px;
    }
  }

}