
.access-fail-40X {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  padding: 20px 35px;
  background-color: #fff7;

  border-top: 3px solid $dominant1;

  @include sm-only {

    flex-flow: column nowrap;
    width: 85%;
    margin: auto;
  }

  &__number {
    margin: 0px 30px 0px 0px;
    padding: 8px 30px 8px 0px;
    border-right: 3px solid black;
    border-bottom: none;

    //font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 1.5rem;

    @include sm-only {
      margin: 0px 0px 30px 0px;
      padding: 0px 8px 30px 8px;
      border-right: none;
      border-bottom: 3px solid black;
      font-size: 1.35rem;
    }
  }

  &__message {
    font-size: 0.97rem;
  }

}


.access-fail-40X__bg-1 {
  //best : 1 3 6 7 8 (9) (10)
  background-image: url("https://s3.eu-west-3.amazonaws.com/myshortlist.co-public/full-background-8.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.access-fail-40X__bg-2 {
  background-image: url("https://s3.eu-west-3.amazonaws.com/myshortlist.co-public/full-background-7.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.access-fail-40X__bg-3 {
  background-image: url("https://s3.eu-west-3.amazonaws.com/myshortlist.co-public/full-background-6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}