

.btn-social {

  width: 38px;
  height: 38px;
  border-radius: 5px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  font-family: 'geomanistbold', 'Lato', sans-serif;
  //font-weight: 900;
  font-size: 1.4rem;

  transition: all 0.3s;

  margin: 20px;
}


.btn-social {

  &--linkedin {
    background-color: $linkedin;
    background-image: linear-gradient(to top, $linkedin, lighten($linkedin, 30%));
    color: white;

    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin: 20px;
    transition: all 0.3s;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.btn-social--share {
  min-width: 120px;
  height: 50px;
  border-radius: 5px;

  border: none;

  //font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 1rem;

  margin: 5px;
}

.btn-social--share {

  &--linkedin {
    background-color: $linkedin;
    background-image: linear-gradient(to top, $linkedin, lighten($linkedin, 30%));
    color: white;
    &:hover {
      background-image: linear-gradient(to top, lighten($linkedin, 10%), lighten($linkedin, 35%));
    }
    &:disabled {
      background-image: linear-gradient(to top, lighten($linkedin, 40%), lighten($linkedin, 60%));
    }
  }

  &--facebook {
    background-color: $facebook;
    background-image: linear-gradient(to top, $facebook, lighten($facebook, 30%));
    color: white;
    &:hover {
      background-image: linear-gradient(to top, lighten($facebook, 10%), lighten($facebook, 35%));
    }
  }

  &--twitter {
    background-color: $twitter;
    background-image: linear-gradient(to top, $twitter, lighten($twitter, 30%));
    color: white;
    &:hover {
      background-image: linear-gradient(to top, lighten($twitter, 10%), lighten($twitter, 35%));
    }
    &:disabled {
      background-image: linear-gradient(to top, lighten($twitter, 10%), lighten($twitter, 35%));
    }
  }

  &--white {
    background-color: #bbb;
    background-image: linear-gradient(to top, #bbb, lighten(#bbb, 30%));
    color: white;
    &:hover {
      background-image: linear-gradient(to top, lighten(#bbb, 10%), lighten(#bbb, 35%));
    }
    &:disabled {
      background-image: linear-gradient(to top, lighten(#bbb, 10%), lighten(#bbb, 35%));
    }
  }

  &--indeed {
    background-color: #e9e9e9;
    background-image: linear-gradient(to top, #e9e9e9, lighten(#e9e9e9, 30%));
    color: $indeed;
    &:hover {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 5%), lighten(#e9e9e9, 10%));
    }
    &:disabled {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 10%), lighten(#e9e9e9, 35%));
    }
  }

  &--monster {
    background-color: #e9e9e9;
    background-image: linear-gradient(to top, #e9e9e9, lighten(#e9e9e9, 30%));
    color: $monster;
    &:hover {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 5%), lighten(#e9e9e9, 10%));
    }
    &:disabled {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 10%), lighten(#e9e9e9, 35%));
    }
  }

  &--poleemploi {
    background-color: #e9e9e9;
    background-image: linear-gradient(to top, #e9e9e9, lighten(#e9e9e9, 30%));
    color: $violet;
    &:hover {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 5%), lighten(#e9e9e9, 10%));
    }
    &:disabled {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 10%), lighten(#e9e9e9, 35%));
    }
  }

  &--regionsjob {
    background-color: #e9e9e9;
    background-image: linear-gradient(to top, #e9e9e9, lighten(#e9e9e9, 30%));
    color: $regionsjob;
    &:hover {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 5%), lighten(#e9e9e9, 10%));
    }
    &:disabled {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 10%), lighten(#e9e9e9, 35%));
    }
  }

  &--apec {
    background-color: #e9e9e9;
    background-image: linear-gradient(to top, #e9e9e9, lighten(#e9e9e9, 30%));
    color: $cyan;
    &:hover {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 5%), lighten(#e9e9e9, 10%));
    }
    &:disabled {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 10%), lighten(#e9e9e9, 35%));
    }
  }

  &--cadreemploi{
    background-color: #e9e9e9;
    background-image: linear-gradient(to top, #e9e9e9, lighten(#e9e9e9, 30%));
    color: $cadreemploi;
    &:hover {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 5%), lighten(#e9e9e9, 10%));
    }
    &:disabled {
      background-image: linear-gradient(to top, lighten(#e9e9e9, 10%), lighten(#e9e9e9, 35%));
    }
  }

  &--myshortlist {
    background-color: $dominant1;
    background-image: linear-gradient(to top, $dominant1, lighten($dominant1, 30%));
    color: white;
    font-weight: 400;
    min-width: 150px;
    padding: 0 15px;
    transition: all 0.3s;
    &:hover {
      background-image: linear-gradient(to top, lighten($dominant1, 10%), lighten($dominant1, 35%));
    }
    &:disabled {
      transition: all 0.3s;
      background-image: linear-gradient(to top, lighten(#bbb, 10%), lighten(#bbb, 35%));
    }
  }
}