
.btn-like {

  border: 2px solid $cyan;
  color: $cyan;
  background-color: white;
  padding: 10px 20px;
  border-radius: 6px;

  font-size: 1.1rem;

  &--active {
    border: 2px solid $cyan;
    background-color: $cyan;
    color: white;
  }
}