
.btn-image-avatar {

  width: 50px;
  height: 40px;

  padding: 0px;
  margin: 5px;

  border: 1px solid grey;
  border-radius: 8px;

  background: transparent;

  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
  }

}