
$myelementcolor: #e7e7e7;

/**
* Profile editor
*/
.profile-editor-V2 {

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

/**
* Profile editor header (3 images on top)
*/
.profile-editor-V2__header {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &__element-right {

  }

  &__element-left {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}


/**
* Columns bloc of the profile editor
*/
.profile-editor-V2__columns {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;

  position: relative;
  bottom: 5px;


  &__column {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

/**
* Elements of the profile header
*/
.profile-editor-V2__header {

  &__element1 {
    position: relative;
    background-color: $myelementcolor;
    width: 410px;
    height: 250px;
    margin: 10px 5px;
  }

  &__element2 {
    position: relative;
    background-color: $myelementcolor;
    width: 200px;
    height: 120px;
    margin: 10px 5px;
  }

  &__element3 {
    position: relative;
    background-color: $myelementcolor;
    width: 200px;
    height: 120px;
    margin: 10px 5px;
  }

  &__element-edit {

    position: absolute;
    top: 15px;
    left: 15px;
  }
}


/**
* Elements of the columns
*/
.profile-editor-V2__columns {

  &__element {

    position: relative;
    background-color: $myelementcolor;
    width: 200px;
    height: 120px;
    margin: 5px 5px;
    padding: 15px;
    animation: fade-in 0.3s;
    overflow: hidden;
    cursor: grab;

    &--tall {
      position: relative;
      background-color: $myelementcolor;
      width: 200px;
      height: 330px;
      margin: 5px 5px;
      padding: 15px;
      animation: fade-in 0.3s;
      overflow: hidden;
      cursor: grab;

    }

    &--flexible {
      position: relative;
      background-color: $newDominant1;
      width: 200px;
      //height: auto;
      margin: 5px 5px;
      padding: 15px;
      animation: fade-in 0.3s;
      overflow: hidden;
      cursor: grab;

    }

    &--last-in-column {
      background-color: white;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  }

}

/**
* Content of elements
*/
.element-content {

  &--delete {

    position: absolute;
    top: 15px;
    right: 15px;
    background-color: white;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    & > * {
      position: relative;
      top: -2px;
    }
  }

  &--image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--hollow {
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  &--text {
    //position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;

    background-color: $newDominant1;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    &__title {
      padding: 8px;
      width: 100%;
      text-align: left;
      font-size: 1.1rem;
      color: white;
    }

    &__content {
      padding: 8px;
      width: 100%;
      //height: auto;
      font-size: 0.9rem;
      text-align: left;
      color: white;
    }

  }

}