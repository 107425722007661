
.landing-page__logos {

  width: 100%;
  max-width: 1500px;

  padding: 120px;

  margin: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;

  @include sm-to-md {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  overflow: hidden;

  & * {
    font-size: 1.3rem;
    line-height: 2;
    color: $blackish7;
    text-align: center;
    @include md-only {
      text-size: 1.2rem;
    }
  }

  & article {
    width: 300px;

    @include sm-to-md {
      margin-bottom: 50px;
    }
  }

  & img {
    width: 90px;
    height: 90px;
    //opacity: 0.8;
    margin-bottom: 15px;
  }

}

.landing-page__logo {
  width: 90px;
  height: 90px;
  margin-bottom: 15px;
  &--big {
    width: 160px;
    height: 160px;
    margin-bottom: 15px;
    @include sm-only {
      width: 90px;
      height: 90px;
    }
  }
}