
.notification-board {

  z-index: 15000;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  //height: 100vh;
  //background-color: rgba(255, 51, 58, 0.38);
  background-color: rgba(255, 255, 255, 0.57);
  //background: transparent;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;

  //padding: 30px 0px;

  @include sm-only {
    //padding: 10px 0px;
  }

}

.notification-board__notification {

  background-color: $green;
  padding: 20px 27px;
  color: #444;
  border-radius: 20px 20px 20px 20px;
  margin: 3px auto;

  width: 90%;
  //max-width: 880px;

}