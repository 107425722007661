

.private-templates-choice {
  width: 100%;
  background: white;
  border: 1px solid #bbb;
  border-radius: 6px;
  margin: 6px 0;
  padding: 3px;
  &:hover {
    border-color: #333;
  }
}