.question-preset {
  display: flex;
  flex-flow: column nowrap;
}

.q-p__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: $grey
  }

  &--selected {
    background-color: $grey
  }
}

.q-p__header__left {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

}

.q-p__header__right {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

}

.q-p__answers {
  display: flex;
  flex-flow: column nowrap;
}

.q-p__answer {
  padding: 5px 0 0 35px;
  font-size: 1rem;
}