
.btn-candidacy {
  border: 0;
  background-color: $blackish4;
  color: white;
  padding: 12px 15px;

  &:hover {
    background-color: lighten($blackish4, 10%);
  }
}