
.ambassadors {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  border-collapse: separate;
  border-spacing: 10px;

  width: 100%;

  & td {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.39);

    //background-color: $grey;
    padding: 20px;
    margin: 10px;
    //border: 10px solid white;
    text-align: center;
    font-size: 1.2rem;

  }

  & tr:nth-child(odd) {
    background-color: $grey;
  }

  & tr:hover {
    background-color: lighten($dominant1, 45%);
  }

}

.ambassador-minified {

  width: 95%;
  margin: 10px auto;
  padding: 20px;
  background-color: white;
  font-size: 1.1rem;

  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.39);

}