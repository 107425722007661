
.btn-plus {

  width: 60px;
  height: 60px;
  border-radius: 50%;

  position: relative;

  background-color: white;

  border: none;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.35);

  transition: all 0.2s;

  overflow: hidden;

  &:disabled {
    opacity: 0.4;
  }

  & img {
    position: absolute;
    top:20%;
    left: 20%;
    width:60%;
    height: 60%;
    //width: 32px;
    //height: 32px;
    transition: all 0.4s;
  }

  & .btn-plus__img--plus {
    opacity: 1;
    transform: rotateZ(0deg) scale(1);
  }
  & .btn-plus__img--squares {
    opacity: 0;
    transform: rotateZ(0deg) scale(0.2);
  }

}

.btn-plus:hover {

  background-color: #fafafa;

  box-shadow: 0 2px 15px 0 rgba(0,0,0,0.45);

  & .btn-plus__img--plus {
    opacity: 0;
    transform: rotateZ(540deg) scale(0.2);
  }
  & .btn-plus__img--squares {
    opacity: 1;
    transform: rotateZ(540deg) scale(1);
  }

}


.btn-plus--v2 {

  &--wide-margin{  margin: 0 20px;}

  width: 50px;
  height: 50px;
  border-radius: 50%;


  border: 4px solid $colors-V2-dominant1;

  background: transparent;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &__plus {

    font-size: 3rem;
    font-family:  'Lato', sans-serif;
    color: $colors-V2-dominant1;

    position: relative;
    top: -2px;

    transition: all 0.3s;
  }

  transition: all 0.2s;

  &:hover {

    border: 4px solid darken($colors-V2-dominant1, 20%);
    & .btn-plus--v2__plus {

      color: darken($colors-V2-dominant1, 20%);
    }
  }

}