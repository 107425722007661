
.business-page__grid--v2 {

  margin: auto;
  width: 95%;
  max-width: $grid-width;
  min-width: 1000px;

  display: grid;

  grid-template-columns: 22% auto;
  grid-template-rows: auto;

  grid-template-areas: "description content";

  @include sm-only {

    width: 95%;
    max-width: 95%;
    min-width: 95%;

    grid-template-columns: 100%;

    grid-template-rows: auto auto;
    grid-template-areas: "content" "description";
  }

  &__description {

    @include sm-only {
      margin-top: 50px;
    }

    grid-area: description;
    //padding: 15px 15px;

    padding-top: $el-margin;
    overflow: hidden;

    //background-color: lightblue;

    & h1 {
      font-weight: bolder;
      color: black;

      position: relative;

      &::before {

        content: '';
        position: absolute;
        top: 32px;
        background-color: black;
        padding: 1.5px 35px;
        border-radius: 60px;

      }
    }
  }

  &__content {

    grid-area: content;
    overflow: hidden;

    //background-color: lightcoral;

    &__header {

      width: 100%;
      overflow: hidden;

      display: flex;
      flex-flow: row wrap;

      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
    }

    &__gallery {

      position: relative;
      width: 100%;
      //height: 100%;

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: stretch;

      //background-color: red;

      align-content: flex-start;

      @include sm-to-md {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
      }

      &__column {

        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: center;

        //background-color: red;

        //height: 100%;

        & > div {
          margin-bottom: $el-margin;
        }

        @include sm-to-md {
          width: 100%;
        }

      }

    }

  }

}



.business-page__side-elements {

  //background-color: lightseagreen;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;

  @include sm-to-md {
    //flex-flow: row nowrap;
    width: 100%;
  }
}