
/*
Template should be used for Kanban and communities
 */

.kanban-template {

  display: grid;

  grid-template-columns: 280px auto;
  grid-template-rows: auto;
  grid-template-areas: "nav body";

  @include md-only {
    grid-template-columns: 260px auto;
  }

  @include sm-only {

    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "nav" "body";

  }

  &__body {

    grid-area: body;

    padding: 40px 25px;

    overflow-x: scroll;

    background-color: $colors-V2-layout-grey;

  }

  &__nav {
    grid-area: nav;

    background-color: #dfdfdf;

    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: stretch;
      padding: 30px 20px 30px 18px;
      @include sm-to-md {
        padding: 10px;
      }
      //position: sticky;
      //top:60px;
    }
  }

}

.kanban-job-title {

  padding: 10px 50px;
  background-color: white;
  min-height: 50px;
  border-radius: 25px;

  max-width: 800px;

  font-weight: bolder;
  font-size: 1.05rem;
  color: #666;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
