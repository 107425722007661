
.octopus {
  width: 95%;
  max-width: 1200px;
}

.octopus-hands {
  //width: 95%;
  //max-width: 1200px;
  background-image: url("https://static.myshortlist.co/octopus-hands.svg");
  background-repeat: no-repeat;
  background-size: cover;
  //background-position: top 200px;

}