
.btn-pool-candidate {

  width: 25px;
  height: 25px;

  background-color: $grey;

  border-radius: 3px;
  border: 1px solid grey;

  transition: all 0.1s;

  margin: 15px 10px 15px 15px;

  &--selected {
    background-color: $dominant1;
  }

}