
.link-box {
  border: 1px solid #bbb;
  //border-radius: 15px 0 0 15px;

  color: #999;
  padding: 9px 20px;

  overflow: hidden;

  //display: flex;
  //flex-flow: row wrap;
  //justify-content: center;
  //align-items: stretch;

  @include sm-only {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  max-width: 100%;

  &__link {
    font-size: 0.8rem;
    @include sm-only {
      font-size: 0.7rem;
    }
  }

  &__button {
    //border-radius: 0 15px 15px 0;
    font-size: 0.8rem;
    margin: 0 0 0 10px;
    @include sm-only {
      margin: 0 0 0 10px;
      font-size: 0.7rem;
    }
  }
}