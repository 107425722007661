
.btn-shortlist-filter {

  background-color: white;
  margin: 7px 0;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.19);

  border: none;

  min-height: 40px;
  border-radius: 20px;

  //border-radius: 6px;

  padding: 9px;

  font-size: 0.96rem;

  position: relative;

  transition: all 0.22s;

  &--selected {
    //box-shadow: 0 1px 5px 0 $dominant1;
    background-color: $colors-V2-dominant1;
  }

  &:active {
    top: 2px;
  }

  &__number {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: 1.2rem;
  }

  &:disabled {
    opacity: 0.4;
  }

  &__small_logo {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    margin-right: 10px;
    &--blocked {
      background-color: #ddd;
    }
    &--imported {
      background-color: #03d1e5;
    }
    &--color1 {
      background-color: #37d23f;
      //background-color: #f9c930;
    }
    &--color2 {
      background-color: #44c1fd;
      //background-color: #37d23f;
    }
    &--color3 {
      //background-color: #44c1fd;
      background-color: #f9c930;
    }
    &--color4 {
      background-color: #e3540e;
    }
  }

}

.btn-shortlist-filter {

  &--white {
    border-left: 9px solid white !important;
  }

  &--black {
    border-left: 9px solid $blackish7 !important;
  }

  &--green {
    border-left: 9px solid desaturate(lighten($green, 10%), 5%) !important;
  }

  &--orange {
    border-left: 9px solid desaturate(lighten(#ff5f3b, 10%), 5%) !important;
  }

  &--red {
    border-left: 9px solid desaturate(lighten($redpink, 10%), 5%) !important;
  }

  &--gold {
    border-left: 9px solid desaturate(lighten($yellow, 10%), 5%) !important;
  }

}

.input-shortlist-filter {
  background-color: white;
  margin: 7px 0;
  width: 98% !important;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.19);

  border: none;

  min-height: 40px!important;
  border-radius: 20px !important;

  //border-radius: 6px;

  padding: 9px!important;

  box-sizing: border-box;

  position: relative;
}