.window--info {

  z-index: 5000003;

  //position: fixed;
  //bottom: 30px;
  opacity: 0;
  //left: 30px;

  width: 400px;
  height: 300px;
  background-color: white;

  animation: window-help-appear 0.2s forwards;

  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.39);

  @include sm-only {
    width: 95vw;
    height: 60vh;
    bottom: 0;
    left: 0;
  }

}

.window--info__wrapper {
  z-index: 3000000;
  position: fixed;
  top: 0;
  left: 0;

  background-color: #4444;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

}

@keyframes window-help-appear {
  0% {
    //bottom: -200px;
    opacity: 0;
    transform: scale(0.7);

  }
  100% {
    opacity: 1;
    transform: scale(1);
    //bottom: 30px
  }
}