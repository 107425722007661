
.editor__btn {

  //font-family: 'Lato', sans-serif;

  border: none;
  //border: 1px solid $dominant1;
  border: 1px solid white;
  background-color: white;
  color: $dominant1;

  width: 100%;

  box-shadow: 0px 3px 9px 0px rgba(#000, 0.3);

  padding: 15px 15px;

  border-radius: 15px;

  margin: 10px;

  font-weight: 900;

  transition: all 0.2s;

  color: $dominant1;

  &:hover {
    //background-color: darken($dominant1, 8%);
    //border: 1px solid lighten($dominant1, 30%);
    box-shadow: 0px 3px 13px 0px rgba(#000, 0.5);

  }

  &:disabled {
    width: 100%;
    //background-color: lighten($dominant1, 40%);
    //border-color: lighten($dominant1, 40%);
    box-shadow: 0px 3px 9px 0px rgba(#000, 0.3);
    color: #bbb;

    &:hover {
      //box-shadow: none;
      //border: none;
      border: 1px solid white;
    }
  }
}