
.profile-links {

  border: 1px solid #bbb;
  padding: 8px;
  margin-right: 10px;

}


.profile-links--v2 {


  &__media {

    position: relative;
    top: 0;

    width: 35px;
    height: 35px;

    border: none;
    background: transparent;
    //opacity: 0.7;

    font-weight: bolder;

    transition: all 0.2s;

    color: white;

    transform: rotateZ(0deg);

    &--rotated {
      transform: rotateZ(-18deg);
    }

    &:hover {
      top: -3px;
      transform: rotateZ(-18deg);

      //opacity: 1;
    }
    & img {
    filter: invert( 100%);
      width: 100%;
      height: 100%;
    }
  }

}
