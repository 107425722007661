
/**
* appearance
*/
.navbar__dropdown {

  border-radius: 12px;
  padding: 20px;

  & li {
    //padding: 12px 42px;
    text-align: left;
    margin: 0;

    font-size: 1.14rem;

    background-color: white;

    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;

    & button {
      //background-color: red;
      padding: 18px 42px;
      width: 100%;
      height: 100%;
    }

    &:first-child {
      border-radius: 12px 12px 0 0;
      border-top: 1px solid #bbb;
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
      border-bottom: 1px solid #bbb;
    }

    &:hover {
      background-color: lighten($dominant1, 40%);
      background-image: linear-gradient(to bottom, lighten($dominant1, 43%),lighten($dominant1, 40%));
    }
  }
}


/**
* Animation
*/
.navbar__dropdown {

  transition: all .5s;

  position: absolute;
  //top: 80px;
  top: 0px;
  right: 60px;

  visibility: hidden;
  opacity: 0;

  &-trigger {

    visibility: visible;
    opacity: 1;

    &:hover {

      .navbar__dropdown {
        visibility: visible;
        opacity: 1;
        transition: all .5s;
        top: 52px;
      }
    }

  }

}

