
.content-displayer {
  width: 95%;
  min-height: 700px;
  //background-color: #02aab0;
  border: 1px solid #bbb;
  margin: auto;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
}

.content-displayer__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}