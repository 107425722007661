
.editor-section {

  width: 100%;
  padding: 30px;

  margin: 50px 0;

  background-color: lighten(#3a76ac, 10%);
  border-radius: 15px;

  &__title {
    text-align: center;
    margin: 35px 0 45px 0;
    font-size: 2rem;
    color: white;
  }

}

.editor-section {

  &__section1 {
    background-color: #f8ce6a;
    border-radius: 15px;
    & .editor-section__title {
      color: darken(#f8ce6a, 25%) !important;
    }
  }

  &__section2 {
    background-color: #88cf8f;
    border-radius: 15px;
    & .editor-section__title {
      color: darken(#88cf8f, 25%) !important;
    }
  }

  &__section3 {
    background-color: #ca99d7;
    border-radius: 15px;
    & .editor-section__title {
      color: darken(#ca99d7, 25%) !important;
    }
  }

  &__section4 {
    background-color: #1e99d7;
    border-radius: 15px;
    & .editor-section__title {
      color: darken(#1e99d7, 25%) !important;
    }
  }

}
