
.regular-media-choice {

  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-items: center;

  & button {
    flex: 1;
    min-width: 150px;
    //flex-shrink: 0;
  }
}