
.production-banner {

  z-index: 1000000;

  background-color: black;
  font-size: 0.9rem;
  color: white;
  padding: 3px 30px;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}