
.btn-check {
  color: $dominant1;
  border: 2px solid $dominant1;
  background-color: white;
  font-size: 1rem;
  //font-weight: bold;
  transition: all 0.2s;
  margin: 7px;
  //font-family: 'Lato', sans-serif;
  border-radius: 9px;
  padding: 9px 15px;

  &--full {
    width: 100%;
  }

  &--checked {
    color: $dominant1text;
    background-color: $dominant1;
  }
}