
.pools {

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  max-width: 1350px;
  margin: auto;

  &__pool {
    position: relative;
    top: 0;
    transition: top 0.1s;
    &:hover {
      //top: -3px;
      box-shadow: 0 2px 6px 0 lighten(desaturate($dominant1, 20%), 0%);
    }

    width: 290px;
    height: 220px;
    background-color: white;
    margin: 15px;

    cursor: pointer;

    overflow: hidden;
    box-shadow: 0 2px 3px 1px rgba(0,0,0,0.39);

    &__background {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 290px !important;
      height: 220px !important;
    }

    &__numbers {
      position: absolute;
      top: 0;
      left: 0;
      width: 290px !important;
      height: 220px !important;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      font-family: 'geomanistbold', 'Lato', sans-serif;
      font-size: 5rem;

      color: $grey;

    }

    &__header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 15px;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #0008;
      padding: 15px;

      font-size: 1.2rem;
      color: white;
      //min-height: 100px;
    }

  }
}