
.results-V2 {

  background-color: $colors-V2-blue2;
  color: white;
  min-height: 100px;
  border-radius: 60px;
  margin: 10px;

  padding: 20px 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-start;

  &__item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    &__title {
      margin-bottom: 8px;
    }

    &__value {
      background-color: white;

      width: 90px;
      height: 60px;
      border-radius: 30px;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      font-size: 0.9rem;
      font-family: 'geomanistbold', 'Lato', sans-serif;
    }

    &__bar {
      position: relative;
      background-color: #fff7;
      width: 80px;
      height: 14px;
      border-radius: 7px;
      margin-top: 10px;
      overflow: hidden;


      &--selected {
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;

        &--0 {
          width: 0;
        }
        &--10 {
          width: 10%;
        }
        &--20 {
          width: 20%;
        }
        &--30 {
          width: 30%;
        }
        &--40 {
          width: 40%;
        }
        &--50 {
          width: 50%;
        }
        &--60 {
          width: 60%;
        }
        &--70 {
          width: 70%;
        }
        &--80 {
          width: 80%;
        }
        &--90 {
          width: 90%;
        }
        &--100 {
          width: 100%;
        }

      }
    }
  }
}

.results-v2__title {
  font-family: 'geomanistblack', 'Lato', sans-serif;
  color: $blackish8;
  font-size: 1.3rem;
  text-align: center;
}

.results-v2__answer {

  margin: 10px;
  background-color: darken($colors-V2-layout-grey, 3%);
  padding: 12px 8px;
  border-radius: 20px;

  &__question {
    margin: 15px auto;
    font-size: 1.1rem;
    text-align: center;
  }
}

.results-v2__skill {
  margin: 10px;
  background-color: $colors-V2-layout-grey;
  padding: 8px;
  border-radius: 20px;

  &--green {
    background-color: $green;
  }

  &--red {
    background-color: lighten($redpink, 20%);
  }
}



.research__results {

  &__result {
    width: 95%;
    max-width: 700px;
    margin: 10px auto;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    background-color: $colors-V2-layout-grey;

    padding: 6px 10px;

    border-radius: 30px;

    animation: fade-in 0.3s;

    &__left {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    &__right {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
    }

    &__avatar {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      margin-right: 10px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__small {
      margin: 0 5px;
      font-size: 0.8rem;
    }

    &__listening {
      background-color: $colors-V2-blue2;
      color: white;
      padding: 3px 8px;
      border-radius: 20px;
      font-size: 0.9rem;
      margin: 0 5px;
    }
  }
}

.results-V2__metadata {
  margin: auto;
  padding: 20px;
}