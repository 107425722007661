
.calendar {



  & tr {

    border-bottom: 1px solid #bbb;

    &:hover {
      background-color: $grey;
    }

  }



  & td {

    padding: 5px 20px;

  }

  &__item--past {
    opacity: 0.3;
  }

  &__item--today {
    background-color: lighten($dominant1, 40%);
  }
}


.calendar__status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: black;
  &--pending {
    background-color: orange;
  }
  &--accepted {
    background-color: $green;
  }
  &--today {
    background-color: $dominant1;
  }
  &--cancelled {
    background-color: $redpink;
  }
  &--past {
    background-color: $green;
  }
}
