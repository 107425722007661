
.editor {
  display: grid;

  grid-template-columns: 50px auto 320px;
  grid-template-rows: auto;
  grid-template-areas: "none body nav";

  @include md-only {
    grid-template-columns: auto 250px;
    grid-template-areas: "body nav";
  }

  @include sm-only {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "body" "nav";
  }

  &__nav {
    grid-area: nav;
    //background-color: blue;

    //padding: 122px 0 0 0;

    @include sm-only {
      padding: 80px 0 0 0;
    }
  }

  &__body {
    grid-area: body;
    //background-color: yellow;

    padding: 0 0 0 30px;

    @include sm-only {
      padding: 0 0 0 0;
    }
  }
}


.editor__section {
  box-sizing: border-box;
  padding: 9px 30px;
  border-left: 15px solid $cyan;
  margin: 20px auto;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.35);
  transition: all 0.4s;
  background-color: white;
  &--ready {
    border-left: 15px solid $cyan;
  }
  &--not-ready {
    border-left: 15px solid $yellow;
  }

  & input {
    width: 90%;
    max-width: 650px;
    border: none;
    text-align: center;
    border-bottom: 1px solid #bbb;
    font-size: 1.12rem;
    font-weight: 900;
    padding: 5px;
  }

  & textarea {
    height: 120px;

    width: 90%;
    max-width: 590px;
    border: none;
    text-align: justify;
    border-bottom: 1px solid #bbb;
    font-size: 1rem;
    padding: 5px;
  }

  @include sm-only {
    padding: 20px 20px;
  }

}

.editor__controls {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 250px;
  margin: auto;
}

.editor__nav {

  &__content {
    position: sticky;
    top:120px;
  }

}

.editor__form__item {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 9px 30px;
  border-left: 15px solid $cyan;
  margin: 20px auto;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.35);

  transition: border-left 0.3s;

  background-color: white;

  &--ready {
    border-left: 15px solid $cyan;
  }
  &--not-ready {
    border-left: 15px solid $yellow;
  }

  & .input-question {
    width: 90%;
    max-width: 780px;
    border: none;
    text-align: center;
    border-bottom: 1px solid #bbb;
    font-size: 1.12rem;
    font-weight: 900;
    padding: 5px;
    @include sm-to-md {
      max-width: 650px;
    }
  }

  & .input-answer {
    width: 90%;
    max-width: 390px;
    @include sm-to-md {
      max-width: 300px;
    }
    border: none;
    text-align: left;
    border-bottom: 1px solid #bbb;
    font-size: 1rem;
    padding: 5px;

  }

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    //& .input {
    //  display: flex;
    //  flex-flow: row nowrap;
    //}
  }

  &__answers {
    margin-left: 20px;
    @include md-only {
      margin-left: 20px;
    }
    @include sm-only {
      margin-left: 10px;
    }

    &__answer {
      animation: fade-in 0.2s forwards;
    }
  }

  &--draggable {
    //transition: all 0.4s;

    &:hover {
      cursor: move;
      //background-color: #e9faff;
    }
  }
}

.img-drag {
  width: 25px;
  height: 25px;
  opacity: 0.2;
  margin:2px;
}

.img-type {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.editor__warning {
  padding: 38px 30px;
  border-left: 15px solid rgba(237, 55, 51, 0.62);
  background-color: rgba(255, 118, 86, 0.19);
  margin: 20px auto;
  //box-shadow: 0 2px 5px 0 rgba(0,0,0,0.35);
}

.editor__item--hollow {
  background-color: #fff4;
  padding: 20px;
  border-radius: 15px;
}

.editor-helper {
  font-size: 0.8rem;
  padding: 7px 0;
  & img {
    margin-right: 15px;
    width: 30px;
    height: 30px;
  }
}

.editor__sumup__grey {
  padding: 16px;
  border-radius: 15px;
  background-color: $grey;
  margin: 15px 0;
}