
// For specific components, check in their files

html {
  //scroll-behavior: smooth;
  color: $font-color;
  //font-family: 'Lato', sans-serif;
}

@font-face {
  font-family: 'muliregular';
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.eot?');
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.eot?#iefix') format('embedded-opentype'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.woff2') format('woff2'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.woff') format('woff'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-muli.svg#muliregular') format('svg');
}

@font-face {
  font-family: 'helveticaregular';
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/helvetica.ttf');
}

@font-face {
  font-family: 'geomanistregular';
}

@font-face {
  font-family: 'geomanistmedium';
}

@font-face {
  font-family: 'geomanistbold';
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.eot?');
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold?#iefix') format('embedded-opentype'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.woff2') format('woff2'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.woff') format('woff'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.svg') format('svg'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/geomanistbold.ttf') format('ttf');
}

//https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.woff

@font-face {
  font-family: 'geomanistblack';
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.eot?');
  src: url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack?#iefix') format('embedded-opentype'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.woff2') format('woff2'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.woff') format('woff'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.svg') format('svg'),
  url('https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/font-geomanistblack.ttf') format('ttf');
}

@font-face {
  font-family: 'geomanistultra';
}

*{
  font-family: 'muliregular', 'helveticaregular', 'Lato', sans-serif;
  color: $font-color;
  font-weight: normal;
  font-style: normal;
}

p {
  color: $font-color;
}

h1, h2, h3, h4, h5, h6 {
  //font-family: 'Ubuntu', sans-serif;
  color: $font-color;
  //font-family: 'Montserrat', sans-serif;

  //font-family: 'Catamaran', sans-serif;
  //font-family: 'Raleway', sans-serif;
  //font-family: 'Cinzel', serif;
  //font-family: 'Neuton', serif;
  //font-family: 'Limelight', cursive;
}

h1 {
  font-size: 1.4rem;
  margin-bottom: 20px;
  text-align: left;
  @include sm-only {
    text-align: center;
  }
  & .splash-title {
    //font-family: 'Indie Flower', cursive;
    font-family: 'Pacifico', cursive;
    //font-family: 'Caveat Brush', cursive;
    //font-family: 'Gloria Hallelujah', cursive;
    //font-family: 'Satisfy', cursive;
    //font-family: 'Leckerli One', cursive;
  }
}

.title--bold {
  font-family: 'geomanistblack', 'Lato', sans-serif;
}

h2 {

}

h3 {

}

h4 {

}

h5 {

}

h6 {

}

.big-title {

  font-family: 'Satisfy', cursive;
  font-size: 3rem;

  //font-family: 'Raleway', sans;
  //font-size: 2rem;

  color: $dominant1;
  @include sm-only {
    font-size: 2.8rem;
  }

  margin: 270px auto 100px auto;
  @include sm-only {
    margin: 190px auto 80px auto;
  }

  text-align: center;

}

.logo {
  //font-family: 'Indie Flower', cursive;
  font-family: 'Pacifico', cursive;
  //font-family: 'Caveat Brush', cursive;
  //font-family: 'Gloria Hallelujah', cursive;
  //font-family: 'Satisfy', cursive;
  //font-family: 'Leckerli One', cursive;
}

.modern-link {
  color: $link-blue;
  text-decoration: underline;
}

ul {

}