
.stats__title {
  font-size: 1.8rem;
  color: $blackish8;
  font-family: 'geomanistbold', 'Lato', sans-serif;
  margin-bottom: 38px;
  position: relative;
  //left: 30px;
  @include sm-only {
    left: 0px;
    font-size: 1.5rem;
  }
}

.stats__grey {
  padding: 40px 0 80px 0;
  background-color: $grey;

}

.stats__keys {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;

  & article {
    background-color: white;
    overflow: hidden;
    width: 200px;
    height: 110px;
    //border-radius: 9px;
    text-align: center;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.35);

    margin: 30px;

    position: relative;
    top: 0;

    transition: all 0.18s;

    &:hover {
      top: -10px;
    }
  }



  &__title {
    font-family: 'geomanistbold', 'Lato', sans-serif;
    color: grey;
    font-size: 1rem;
  }

  &__value {
    font-family: 'geomanistbold', 'Lato', sans-serif;
    color: $dominant1;
    font-size: 1.4rem;
  }

}

.table-stats-percent {
  & td {
    background-color: white;
    padding: 20px;
    border: 10px solid $grey;
  }
}