
.business-page {

}

@keyframes avatar-animation {
  0% {
    opacity: 0;
    right: 30px;
    //transform: rotateZ(50deg);
    transform: rotateZ(-50deg);
  }
  100% {
    opacity: 1;
    right: -20px;
    //transform: rotateZ(-20deg);
    transform: rotateZ(0deg);
  }
}

@keyframes block-animation {
  0% {
    opacity: 0;
    right: -50px;
    transform: rotateZ(10deg);
  }
  100% {
    opacity: 1;
    right: 0;
    transform: rotateZ(0deg);
  }
}

@keyframes socials-animation {
  0% {
    opacity: 0;
    right: 30px;
    transform: rotateX(50deg);
  }
  30% {
    opacity: 0;
    right: 30px;
    transform: rotateX(50deg);
  }
  100% {
    opacity: 1;
    right: -20px;
    transform: rotateZ(-20deg);
  }
}

@keyframes darken-animation {
  0% {
    filter: brightness(100%);
  }
  100% {
    filter: brightness(59%);
  }
}



/**
* On PCs
*/

.business-page__header {

  width: 100%;
  height: $header-height;

  //margin-top: 80px;
  @include sm-only {
    padding-top: 90px;
    padding-bottom: 15px;
    height: auto;
  }

  position: relative;
  top: 0;
  left: 0;

  //padding: 40px 0;
  background-color: #0001;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;

  &__picture {

    animation: darken-animation 1s forwards;
    animation-delay: 0s;

    object-fit: cover;

    position: absolute;
    top:0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: -1;

  }

  &__content {

    //background-color: #99000055;
    //opacity: 0.5;
    width: 95%;
    max-width: $header-content-width;

    //padding: 30px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;


    &__avatar {

      width: 220px;
      height: 220px;

      position: relative;
      top: 50px;

      z-index: 9999;

      border-radius: 110px;
      margin-right: 30px;
      object-fit: cover;

      opacity: 0;
      animation: avatar-animation 0.8s forwards;
      animation-delay: 0.2s;

      transition: all 0.2s;
      //&:hover {
      //  transform: rotateY(180deg);
      //}

      box-shadow: 0 1px 5px 0 rgba(0,0,0,0.28);

      &:hover {
        //top: 30px;
        //transform: rotateX(10deg);
      }

      @include sm-only {
        width: 140px;
        height: 140px;
      }
    }

    &__text {

      padding: 20px 50px;

      opacity: 0;
      position: relative;
      animation: block-animation 0.4s forwards;
      animation-delay: 1.6s;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: flex-start;

      height: 100%;

      //background-color: red;

      &__title {
        font-family: 'geomanistbold', 'Lato', sans-serif;

        font-size: 2.22rem;
        letter-spacing: 1px;
        color: white;
      }

      &__detail {
        color: white;
      }

    }


  }

}


/**
* On Smartphones
*/



.business-page__header--smartphones {

  &__content {

    width: 95%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;

    &__avatar {

      width: 200px;
      height: 200px;
      border-radius: 50%;

      opacity: 0;
      animation: avatar-animation 0.8s forwards;

    }

    &__title {

      font-family: 'geomanistbold', 'Lato', sans-serif;

      margin: 15px auto;

      font-size: 2rem;
      letter-spacing: 1px;
      color: white;

    }


  }

}
