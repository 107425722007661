
.new-avatar--small {

  width: 40px;
  height: 40px;
  border-radius: 20px;
  object-fit: cover;
  margin: 5px;
  & * {
    object-fit: cover;
  }
}