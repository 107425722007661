
.btn-discreet {

  background-color: white;
  border: 1px solid #bbb;
  color: #bbb;

  padding: 4px 8px;

  margin: 5px;

  border-radius: 4px;

  transition: all 0.3s;

  &:hover {
    color: #444;
    border-color: #444;
  }

}