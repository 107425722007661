
/* The switch - the box around the slider */
.switch-V2 {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 31px;
}

/* Hide default HTML checkbox */
.switch-V2 .checkbox-on-off {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.checkbox-on-off:checked + .slider {
  background-color: $colors-V2-blue2;
}

.checkbox-on-off:focus + .slider {
  box-shadow: 0 0 1px $colors-V2-blue2;
}

.checkbox-on-off:checked + .slider:before {
  -webkit-transform: translateX(29px);
  -ms-transform: translateX(29px);
  transform: translateX(29px);
}

/* Rounded sliders */
.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: 50%;
}