
.window-candidate-share__btn-plus {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.window-candidate-share__div-invisible{
  opacity: 0;
  height:1px;
  width:1px;
  overflow: hidden;
}

.window-candidate_link-box {
  background-color: white;
  min-height: 60px;
  border-radius: 30px;
  width: 90%;
  max-width: 730px;
  margin-bottom: 15px;
  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  color: #666;

}



.candidate-window_link-header{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 490px!important;
  margin-left: 13px;
}

.candidate-window_input{
  background-color: white;
  width: 150px!important;
  border: none;
  min-height: 30px!important;
  border-radius: 20px !important;
  padding-left: 10px;
}

.candidate-window_link-header_name{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}