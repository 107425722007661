
.assistant-layout {

  position: relative;

  margin: 40px auto 45px auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;

  @include sm-only {
    //margin: 35px auto;
  }

  &--animated {
    position: relative;
    animation: assistant-appear 0.4s;
  }

}

.assistant--min-height {
  //min-height: 250px;
}

@keyframes assistant-appear {
  0% {
    opacity: 0;
    top: 30px;
    //transform: perspective(1000px) rotateY(10deg);
  }
  100% {
    opacity: 1;
    top: 0px;
    //transform: perspective(1000px) rotateY(0deg);
  }
}

@keyframes assistant-disappear {
  0% {
    opacity: 1;
    top: 0px;
    //transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    top: 50px;
    //transform: perspective(1000px) rotateY(10deg);
  }
}

.assistant-layout {
  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
  }
  &__comment {
    width: 85%;
    font-size: 0.8rem;
    margin: auto;
  }
}

.assistant-layout--column {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

}


.assistant-layout__skills {

  width: 100%;
  max-width: 600px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  &__skill {


    background-color: $dominant1text;
    margin: 5px;

    padding: 15px 25px;

    border-radius: 8px;

    border: 2px solid $dominant1;
    color: $dominant1;

    transition: all 0.4s;

    font-size: 0.92rem;

    &--selected {
      color: $dominant1text;
      background-color: $dominant1;
    }
  }
}

.animation-assistant-appear {
  position: relative;
  animation: assistant-appear 0.3s forwards;
}


assistant-layout--column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}