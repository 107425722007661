
.btn--hollow {
  background: transparent;
  border: none;
  padding: 0px;

  &:focus {outline:0;}

  &:disabled {
    opacity: 0.4 !important;
  }

}

.btn--flex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}