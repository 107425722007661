/**
Fenêtre globale
 */
.window-tutorial__main-container {

  //position: fixed;
  //top: 0;
  //left: 0;
  //width: 100vw;
  //height: 100vh;
  //
  //background-color: #4447;

  z-index: 499999;

  @include sm-only {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}

.window-tutorial {

  font-size: 0.95rem;

  & * {
    font-size: 0.95rem;
  }

  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #FFFFFF;
  border-radius: 25px;
  width: 400px;
  height: 600px;
  text-align: center;
  padding: 28px 40px;
  z-index: 5000000;
  box-shadow: 0px 0px 9px 0px rgba($blackish4, 0.5);

  opacity: 0;
  transform: scale(0.6);

  animation: window-tutorial-appear 0.4s forwards;

  @include sm-only {
    width: 95vw;
    margin: auto;
    position: static;
    bottom: auto;
    right: auto;
    height: 95vh;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    height: 90%;
  }

  &__main-content {
    flex-grow: 1;
    height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__secondary-content {
    flex-grow: 0;
  }
}

.window-tutorial__container {
  word-wrap: break-word;
  text-align: left;
}

.window-tutorial__picture {
  width: 300px;
  height: 200px;
  background-color: #2b2b2b;
  margin: 10px;
}


.window-tutorial__title1 {
  font-size: 1.2rem;
  text-align: center;
  margin: 10px;
  font-weight: bold;
}

.window-tutorial__title2 {
  text-align: left;
  margin: 0px 10px 10px 10px;
  font-weight: bold;
}

.tutorial-step-button {

  background-color: #d5d1d1;
  width: 8px;
  height: 10px;
  border-radius: 5px;
  margin: 25px 4px 20px 4px;
  border: none;

  &--selected {
    background-color: #636363;
  }
}

.window-tutorial__b {
  font-weight: bold;
}

.window-tutorial__ul {

  display: list-item !important;
  list-style-type: circle !important;

  & li {
    display: list-item !important;
    list-style-type: circle !important;
    margin: 6px auto;
  }

  & li:before {
    content: "○ ";
    //display: list-item !important;
    list-style-type: circle !important;
  }
}


// top: 50 puis 0 (attention : mettre ton composant en position: relative, ou absolute ou fixed)
// transform: scale(0.5)

@keyframes window-tutorial-appear {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}