
.pool-input--main {
  width: 100%;
  max-width: 450px;
  height: 40px;
  text-align: center;
  font-size: 1.3rem;
  border-radius: 40px;
}

.pool__result {
  text-align: center;
  min-width: 1100px;

  & tr {
    background-color: $grey;
  }
  & tr:nth-child(odd) {
    background-color: white;
  }

  & td {
    padding: 17px;
    text-align: left;
    font-size: 1.2rem;
    & * {
      font-size: 1.2rem;
    }
  }
}

.pool-navbar-top {

  //background-color: #333;
  background-color: $dominant1;

  //background-color: $yellow;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  padding-top: 100px;
  padding-bottom: 30px;
  padding-left: 120px;

  &__bottom {

    margin-top: 15px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__bloc {

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    margin-right: 20px;

    & span {
      font-size: 0.8rem;
      color: white;
    }

    & input {
      //padding: 14px 10px;
      margin: 0;
      width: 160px;
      border: 1px solid #666;

      height: 40px;
      border-radius: 40px;

    }
  }

}

.pool-navbar-top__white-section {
  background-color: white;
  //min-width: 400px;
  padding: 12px 30px !important;
  height: 58px !important;
  text-align: left;
  margin: 0;
  border: 1px solid #666;

  border-radius: 30px !important;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

}

.btn-research {
  padding: 15px 23px;
  & img {
    width: 21px;
    height: 21px;
  }
  background: white;
  border: 1px solid #666;
  border-radius: 5px;
  &:hover {
    background: #eee;
  }
}

.btn-research2 {

  width: 52px;
  height: 52px;

  //height: 40px;

  background: white;
  border: 1px solid #666;
  //border-radius: 5px;
  border-radius: 52px;


  color: #444;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  transition: all 0.2s;


  &--active {
    background: #444;
    color: white;
  }

}

/**
 * input
 */
.pool-navbar-top__main {
  width: 70%;
  min-width: 830px;
  padding: 12px 30px !important;
  height: 40px !important;
  text-align: left;
  margin: 0;
  border: 1px solid #666;

  border-radius: 40px !important;
}

.pool-navbar-top__date {
  margin: 0;
  width: 160px;
  border: 1px solid #666;

  height: 40px !important;
  border-radius: 40px !important;
}

.pool-navbar-top__input {
  margin: 0;
  width: 160px;
  border: 1px solid #666;

  height: 40px !important;
  border-radius: 40px !important;
}



.pool_last-exchange-buttons {

  padding: 3px 5px;
  font-size: 0.8rem;
  margin: 3px;
  border-radius: 30px;

  opacity: 0.8;

  transition: 0.2s;

  &--selected {
    opacity: 1;
    border: 3px solid $blackish6 !important;
  }

  &:hover {
    opacity: 1;
  }

  &--old5 {
    background-color: #ddd;
    border: 3px solid #ddd;
  }
  &--old4 {
    background-color: #e3540e;
    border: 3px solid #e3540e;
  }
  &--old3 {
    background-color: #f9c930;
    border: 3px solid #f9c930;
  }
  &--old2 {
    background-color: #44c1fd;
    border: 3px solid #44c1fd;
  }
  &--old1 {
    background-color: #37d23f;
    border: 3px solid #37d23f;
  }
}
