
.splash {

  width: 100%;
  height: 100vh;

  position: relative;

  //margin-bottom: 120px;

  min-width: 1100px;

  @include sm-to-md {
    min-width: 100%;
    margin-bottom: 20px;
  }

  padding: 70px 0 30px 0;
  @include sm-only {
    padding: 80px 0 20px 0;
  }

  &__block {

    width: 100%;
    height: 100%;

    overflow: hidden;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;

    position: relative;

    @include sm-only {
      align-items: center;
    }

    background-color: $dominant2;

    &__content {

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;

      margin-left: 10%;
      @include sm-only {
        margin-left: 0;
      }

      &__website {
        font-family: "Pacifico", cursive;
        font-size: 7rem;
        color: lighten($dominant2, 20%);
        @include sm-only {
          font-size: 3rem;
        }
      }

      &__title, &__title span, &__title * {
        //font-family: "Lato", sans-serif;
        font-family: 'geomanistblack', 'Lato', sans-serif;
        font-weight: 900;
        font-size: 4rem;
        @include sm-only {
          font-size: 1.7rem;
        }
      }

      &__title {
        position: relative;
        top: -60px;
        @include sm-only {
          top: -30px;
        }
      }

      &__subtitle {
        position: relative;
        top: -42px;
        font-size: 2rem;
        color: white;
        @include sm-only {
          font-size: 1.1rem;
          top: -35px;
        }
      }

    }

  }

}

.splash-square, .splash-figure {
  position: absolute;
  @include sm-only{
    display: none;
  }
}

.splash-square {
  width: 50%;
  height: 70%;
  background-color: lighten($dominant2, 20%);
  right: -250px;
  transform: rotateZ(16deg);
  border-radius: 20px;

  opacity: 0;
  animation: appear-right 0.4s forwards;
  animation-delay: 0.8s;
}

.splash-figure {
  bottom: 30px;
  background-image: url("https://s3.eu-west-3.amazonaws.com/myshortlist.co-public/aurelie-splash--brighter.png");
  background-size: cover;

  opacity: 0;
  animation: fade-in 0.6s forwards;
  animation-delay: 0.1s;

  @include md-only {
    right: 8%;
    width: 32%;
    max-width: 400px;
    height: 87%;
  }
  @include from-lg {
    right: 14%;
    width: 32%;
    max-width: 450px;
    height: 87%;
  }
}


@keyframes appear-right {
  0% { opacity: 0; right: -260px;}
  100% { opacity: 1; right: -250px;}
}