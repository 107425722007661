
$offset: -30px;

.all-avatars {
  margin: auto;
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: center;
  align-items: center;

  &--offset {
    position: relative;
    right: -$offset;
  }

  & .all-avatars__img {
    position: relative;

    object-fit: cover;

    border:4px solid #fff;

    width: 60px;
    height: 60px;

    border-radius: 50%;

    &:nth-child(2) {
      right: $offset;
    }
    &:nth-child(3) {
      right: 2*$offset;
    }
    &:nth-child(4) {
      right: 3*$offset;
    }
    &:nth-child(5) {
      right: 4*$offset;
    }
    &:nth-child(6) {
      right: 5*$offset;
    }
    &:nth-child(7) {
      right: 6*$offset;
    }
    &:nth-child(8) {
      right: 7*$offset;
    }
    &:nth-child(9) {
      right: 8*$offset;
    }
    &:nth-child(10) {
      right: 9*$offset;
    }
    &:nth-child(11) {
      right: 10*$offset;
    }
    &:nth-child(12) {
      right: 11*$offset;
    }
  }


  &--big {
    & .all-avatars__img {
      object-fit: cover;
      width: 87px;
      height: 87px;
    }
  }

}