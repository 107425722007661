

.teams {

  width: 90%;
  //max-width: 1400px;
  margin: auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  @include sm-only {
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }

}

.teams__team {

  position: relative;

  width: 380px;
  min-height: 640px;
  max-height: 640px;

  overflow-y: auto;

  border-radius: 20px;

  padding: 11px;

  background-color: white;

  margin: 20px 15px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  animation: fade-in 0.3s;

  &__title1 {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: 0.9rem;
  }

  &__title2 {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: 1.5rem;
    //margin-bottom: 40px;
  }

  &__button {
    margin: 6px;
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: 1rem;
    color: white;
    border: none;
    background-color: $newDominant1;
    border-radius: 20px;
    height: 40px;

    padding: 0 15px;


    &:hover {
      background-color: lighten($newDominant1, 5%);
    }
  }

}

.teams__team__add-team {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.teams__team__add-member {

  width: 100%;
  margin: 40px 15px 15px 15px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.teams__team__member {

  position: relative;

  width: 100%;
  height: 85px;

  flex-shrink: 0;

  border-radius: 45px;

  margin: 4px 0;

  padding: 0 10px;
  background-color: darken($colors-V2-layout-grey, 4%);

  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  cursor: grab;

  &--invite {
    opacity: 0.4;
  }
}

.teams__team__member {

  &__avatar {
    position: relative;
    height: 65px;
    width: 65px;
    border-radius: 35px;
    background-color: black;
    //position: absolute;
    //top: 5px;
    //left: 5px;
    overflow: hidden;

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--empty {
      background-color: #1feed6;
    }
  }

  &__content {
    margin-left: 10px;

    &__title {
      font-family: 'geomanistblack', 'Lato', sans-serif;
      font-size: 1.3rem;
    }

    &__email {
      font-size: 0.85rem;
    }

    &__role {

    }
  }
}