
.btn-new-blue {
  background: $colors-V2-blue2;
  color: white;
  border-radius: 10px;
  border: none;
  font-weight: bolder;
  padding: 15px 30px;

  transition: background 0.2s;

  &:hover {
    background: lighten($colors-V2-blue2, 10%);
  }

  &:disabled {
    background: lighten($colors-V2-blue2, 10%);
  }
}