
@keyframes badass-appear {
  0% {
    top: 30px;
    left: 30px;
    opacity: 0.2;
    transform: rotateZ(-4deg) rotateX(20deg);
  }
  100% {
    top: 0;
    left: 0;
    opacity: 1;
    transform: rotateZ(0deg) rotateX(0deg);
  }
}


.candidates-pool {

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  &__candidate {

    position: relative;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    width: 325px;

    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.58);
    overflow: hidden;
    margin: 5px;
    border-radius: 0px;

    cursor: pointer;

    top: 0;

    transition : all 0.1s;

    animation: badass-appear 0.3s forwards;

    &:hover {
      top:-3px;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.78);
    }

    &--highlight {
      box-shadow: 0 3px 10px 0px $redpink;
      border: 3px solid $redpink !important;
    }

    &--hollow {
      opacity: 0.3 !important;
    }

    &--bg10 { // Alive
      $c1: darken(lighten($dominant1, 20%), 0%);
      background: linear-gradient(90deg, $c1 0%, lighten($c1, 15%) 100%);
    }
    &--bg9 {
      $c2: darken(lighten($dominant1, 20%), 0%);
      background: linear-gradient(90deg, $c2 0%, lighten($c2, 15%) 100%);
    }
    &--bg8 {
      $c3: darken(lighten($dominant1, 20%), 5%);
      background: linear-gradient(90deg, $c3 0%, lighten($c3, 15%) 100%);
    }
    &--bg7 {
      $c4: darken(lighten($dominant1, 20%), 20%);
      background: linear-gradient(90deg, $c4 0%, lighten($c4, 15%) 100%);
    }
    &--bg6 {
      $c5: darken(lighten($dominant1, 20%), 15%);
      background: linear-gradient(90deg, $c5 0%, lighten($c5, 15%) 100%);
    }
    &--bg5 {
      $c6: darken(lighten($dominant1, 20%), 20%);
      background: linear-gradient(90deg, $c6 0%, lighten($c6, 15%) 100%);
    }
    &--bg4 {
      $c7: darken(lighten($dominant1, 20%), 25%);
      background: linear-gradient(90deg, $c7 0%, lighten($c7, 15%) 100%);
    }
    &--bg3 {
      $c8: darken(lighten($dominant1, 20%), 32%);
      background: linear-gradient(90deg, $c8 0%, lighten($c8, 15%) 100%);
    }
    &--bg2 {
      $c9: darken(lighten($dominant1, 20%), 40%);
      background: linear-gradient(90deg, $c9 0%, lighten($c9, 15%) 100%);
    }
    &--bg1 { // Dead
      $c10: darken(lighten($dominant1, 20%), 50%);
      background: linear-gradient(90deg, $c10 0%, lighten($c10, 15%) 100%);
    }


    &--v2 {
      position: relative;

      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;

      width: 325px;

      box-shadow: 0 1px 3px 0 rgba(0,0,0,0.58);
      overflow: hidden;
      margin: 6px;
      border-radius: 2px;

      cursor: pointer;

      top: 0;

      transition : all 0.1s;

      animation: badass-appear 0.3s forwards;

      &:hover {
        top:-3px;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.78);
      }

      &--highlight {
        box-shadow: 0 3px 10px 0px $redpink;
        border: 3px solid $redpink !important;
      }

      &--hollow {
        opacity: 0.3 !important;
      }

      &__top {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        color: black;
      }

      &__color {
        height: 11px;
        width: 100%;
      }

      @include sm-only {
        width: 90%;
        margin: 10px;
      }
    }
  }
}

.input-filter {
  width: 220px;
  height: 36px;
  //border-radius: 19px;
}