
.btn-importance-item {

  background: transparent;
  border: none;

  font-size: 1.5rem;
  @include sm-to-md {
    font-size: 1.3rem;
  }

  padding: 0;
  margin: 0 8px;

  color: #bbb;

  font-family: 'geomanistblack', 'Lato', sans-serif;

  &:hover {
    color: $dominant1;
    & .btn-importance-item--text {
      color: #bbb;
    }
  }

  &--text {
    font-size: 1.3rem;
    &:hover {
      color: #bbb;
    }
  }

  & img {
    width: 30px;
    height: 30px;
    color: #bbb;
    opacity: 0.3;
  }

  &--selected {
    color: $dominant1;
    & img {
      opacity: 1;
    }
  }
}

.btn-importance-item--smaller {
  font-size: 1.15rem;
}