
.navbar-smartphone {

  z-index: 12000;

  position: relative;
  //position: fixed;
  //bottom: 0;
  //left: 0;

  width: 100%;
  max-height: 88%;

  //border-top-left-radius: 22px;
  //border-top-right-radius: 22px;
  border-radius: 22px;
  //width: 90%;
  //margin-bottom: 20px;

  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.38);

  background-color: white;
  background-image: linear-gradient(to bottom, white 80%, #f5f5f5);

  padding: 20px 40px 60px 40px;

  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__avatar {
    object-fit: cover;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    & > * {
      background-color: $dominant1;
      width: 120px;
      height: 120px;
      border-radius: 60px;

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      color: $dominant1text;
      font-family: 'Pacifico', cursive;
      font-size: 2rem;

    }
  }

}

.navbar-smartphone {
  &--appear {
    animation: navbar-appear 0.5s forwards;
  }
  &--disappear {
    animation: navbar-disappear 0.6s forwards;
  }
}

@keyframes navbar-appear {
  0% {
    opacity: 0.8;
    bottom: -100%;
  }
  100% {
    bottom:0%;
    opacity: 1;
  }
}

@keyframes navbar-disappear {
  0% {
    opacity: 1;
    bottom: 0%;
  }
  100% {
    bottom:-100%;
    opacity: 0.7;
  }
}

.navbar-smartphone__items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  & li {
    width: 100%;
    text-align: center;
    &:hover {
      & * {
        color: #444;
      }
      background-color: lighten($dominant1, 40%);
    }
    & * {
      width:100%;
      padding: 17px 0px;
      text-align: center;
      font-size: 1.2rem;
      color: #aaa;
    }
  }
}

.navbar-smartphone-container {

  z-index: 12000;

  position: fixed;

  top:0;
  left: 0;

  width: 100%;
  height: 100vh;

  //background-color: #0004;
  background-color: $background-window-color;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;

  transition: all 0.2s;

  padding: 20px;

  &--hidden {
    background-color: #0000;
  }

}