
/** **********************************
* Navbar component
*********************************** */
.navbar {

  //padding: 21px 90px;
  padding: 10px 90px;
  width: 100%;
  min-width: 700px;
  margin: auto;

  z-index: 10000;

  position: fixed;
  top: 0;

  background-image: linear-gradient(to bottom, #ffff, rgba(255, 255, 255, 0.84));
  box-shadow: none;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  transition: all 0.6s;

  &--white {
    //background-color: #fffe;
    //background-image: linear-gradient(to bottom, #ffff, #fffe);
  }

  &--scrolled {
    background-color: #fffe;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.18);
  }

  @include sm-only {
    padding: 20px;
    width: 100%;
    min-width: 0px;
  }

}


/** **********************************
* Menu logo
*********************************** */
.navbar__logo {

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  margin-right: 30px;

    //font-family: 'Pacifico', cursive;
    //font-family: 'Gloria Hallelujah', cursive;
    //font-family: 'Satisfy', cursive;
    //font-family: 'Leckerli One', cursive;

  &__image {

    width: 38px;
    height: 38px;
    background-color: $blackish6;

    border-radius: 8px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    transform: rotateZ(-15deg);

    transition: background-color 1s;

    &--grey {
      //background-color: #f9f9f9;
    }

    @include sm-only {
      width: 40px;
      height: 40px;
      border-radius: 6px;
    }

    &__content {
      transform: rotateZ(15deg);
      font-family: 'Pacifico', cursive;
      //font-family: 'Leckerli One', cursive;
      color: white;
      font-size: 1.4rem;
      line-height: 1;

      position: relative;
      bottom: 4px;
      left:2px;

      transition: color 1s;

      &--red {
        //color: $black;
      }

      @include sm-only {
        font-size: 1.2rem;
        position: relative;
        bottom: 2px;
      }
    }

  }

  &__text {
    //font-family: 'Pacifico', cursive;
    //font-family: 'Gloria Hallelujah', cursive;
    font-family: 'Satisfy', cursive;
    //font-family: 'Leckerli One', cursive;
    color: $dominant1;

    font-size: 1.4rem;

    margin: 0px 25px;

    @include sm-only {
      font-size: 1.1rem;
    }

    transition: all 1s;

    &--visible {
      opacity: 1;
    }

    &--hidden {
      opacity: 0;
    }

    &--grey {
      font-family: 'Satisfy', cursive;
      font-size: 1.1rem;
      margin-left: 30px;
    }

  }


}

/** **********************************
* Menu items
*********************************** */
.navbar__items {

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  //font-family: 'Lato', sans-serif;
  color: #777;
  //color: lighten($dominant1, 10%);

  & .selected {
    //font-size: 1rem;
    color: #333;
  }

  & li {

    margin-left: 5px;

    & * {

      transition: color 0.4s;

      //font-family: 'Lato', sans-serif;
      font-size: 1.14rem;
      color: #777;
      &:hover {
        color: #333;
      }

      @include sm-only {
        font-size: 0.93rem;
      }

    }

  }

}


.navbar__items__languages {

  margin: 0px 35px;
  font-size: 0.8rem !important;

  &__language {

    //font-family: 'Lato', sans-serif;
    font-size: 0.8rem !important;
    color: #777;

    &--selected {
      font-weight: bolder;
      color: #666;
    }
  }
}

.btn-holder {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}