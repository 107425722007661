
.gdpr-warning {

  background-color: #efbbc9;
  font-size: 0.9rem;
  padding: 10px 15px;

  border: 1px solid #e33b6a;

  border-radius: 9px;

  &--blue {
    background-color: #bbd4ef;
    border: 1px solid #3ba0e3;
  }
}

.gdpr-warning__btn {

  width: 100%;
  margin: 12px 5px 5px 5px;
  border: none;
  padding: 4px 6px;
  border-radius: 20px;
  font-size: 0.9rem;
  background-color: #e33b6a;
  color: white;
}

.gdpr-warning__title {

  font-weight: bolder;
  margin-bottom: 15px;
}