

.editor--inverted2 {

  display: grid;

  // background-color: #ddd; // lighten($dominant1, 40%)

  background-color: lighten($yellow, 10%);

  grid-template-columns: 310px auto;
  grid-template-rows: auto;
  grid-template-areas: "nav body";

  @include md-only {
    grid-template-columns: 260px auto;
  }

  @include sm-only {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "nav" "body";
  }

  &__body {
    grid-area: body;
    //background-color: $grey;
    //padding: 40px;

    background-color: white;

  }

  &__nav {
    grid-area: nav;
    //background-color: #e0e0e0;
    //background-color: $cyan;
    //background-color: #f1f1f1;
    //background-color: $dominant1;
    //border-right: 1px solid #bbb;

    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: stretch;
      padding: 30px;
      @include sm-to-md {
        padding: 10px;
      }
      //position: sticky;
      //top:60px;
    }
  }

}