
.file-drag-box {

  width: 340px;
  height: 340px;

  &--unsquared {
    height: 200px;
  }

  overflow: hidden;

  border: 3px dashed $yellow;

  background-color: lightyellow;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  position: relative;

}

.file-drag-input {

  position: absolute;
  top: 0;
  left: 0;

  &--unsquared {
    height: 200px;
  }

  width: 340px;
  height: 340px;

  opacity: 0;

}

.file-drag {

  //position: absolute;
  //top: 0;
  //left: 0;

  width: 54px;
  height: 54px;

  background-color: $yellow;

  border-radius: 10px;

  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  & img {
    width: 40px;
    height: 40px;
    filter: invert(100%);
  }

  &:hover {
    background-color: darken($yellow, 10%);
  }


}