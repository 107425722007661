
.filters {

  background-color: white;
  margin: 15px auto;
  width: 90%;
  max-width: 1500px;
  //height: 90px;
  border-radius: 45px;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  padding: 12px 50px;

  @include sm-only {
    height: auto;
    flex-flow: column wrap;
  }

  &__element {

    margin: 10px 15px;

    &--center {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }

    @include sm-only {
      margin: 25px 20px;
    }

    &__title {

      font-family: 'geomanistblack', 'Lato', sans-serif;
      color: $colors-V2-blue2;

      text-align: left;

      margin-bottom: 4px;

    }

    &__content {
    }

    &__input {

      margin: 0 !important;
      width: 160px !important;
      border: none !important;
      border-bottom: 1px solid #ccc !important;
      border-radius: 0 !important;
      text-align: left !important;
    }

    &--disabled {

      opacity: 0.5;
    }

  }

}


.filters__select {
  height: 36px;
  border-radius: 18px;
  font-size: 0.9rem;
}