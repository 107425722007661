
.btn-camera {

  width: 150px;
  height: 150px;

  border-radius: 20%;

  //background: transparent;

  border: none;

  box-shadow: 0 2px 15px 0 white;
  background-color: #fff9;

  transition: all 0.2s;

  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 15px 0 rgba(0,0,0,0.45);
    & img {
      opacity: 0.8;
      width: 35px;
      height: 35px;
    }
  }

  & img {
    transition: all 0.2s;
    background-color: white;
    border-radius: 6px;
    width: 28px;
    height: 28px;
    opacity: 0.6;
  }

}


.input-camera {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}


.btn-camera2 {

  background: transparent;

  border: none;

  transition: all 0.2s;

  cursor: pointer;

  &:hover {
    & img {
      opacity: 0.6;
      width: 35px;
      height: 35px;
    }
  }

  & img {
    transition: all 0.2s;
    background: transparent;
    width: 40px;
    height: 40px;
    opacity: 0.8;
    filter: invert(0.10);
  }

}