
.btn-inline-dictionary {

  background: white;

  font-size: 0.85rem;

  border: 1px solid #444;
  color: #444;

  margin: 1px 5px;

  padding: 3px 6px;

  transition: all 0.2s;

  &:disabled {
    border: 1px solid #bbb;
    color: #bbb;
  }

}