

.apply__header {
  width: 100%;
  max-width: 900px;
  margin: auto;

  &__title {
    opacity: 0.8;
    margin-bottom: 10px;
  }

  &__body {
    opacity: 0.7;
    font-size: 0.9rem;
    text-align: justify;
  }
}

.apply__sumup {

  width: 100%;
  max-width: 650px;
  margin: auto;

  border-radius: 12px;

  padding: 30px;

  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.28);

}

.apply__sumup__table {
  & td {
    padding: 12px 10px;
    @include sm-only {
      padding: 6px 6px;
    }
  }
}