
.message {
  padding: 15px 25px 15px 40px;
  //border-left: 4px solid $dominant1;
  margin: 30px auto;

  border-radius: 20px;

  width: 90%;
  max-width: 600px;

  &--warning {
    background-color: #ffeab7;
    color: #634c00;
    //border-left-color: #e28e00;
  }
}