
.color-code {

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  &__element {

    //width: 200px;

    &__color {

      cursor: pointer;

      width: 40px;
      height: 40px;
      margin:10px;
      border-radius: 10px;
    }

    &__text {
      text-align: center;
    }
  }

}