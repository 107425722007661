
.shortlists {

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  @include sm-only {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  &__shortlist {

    transition: all 0.17s;
    width: 98%;
    max-width: 950px;
    //min-height: 220px;
    //max-width: 500px;

    padding: 10px 20px;
    margin: 10px;

    background-color: white;

    overflow: hidden;

    box-shadow: 0 2px 3px 1px rgba(0,0,0,0.39);
    //border-radius: 10px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;

    &__line1, &__line2 {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

    }
    &__line1 {

      margin-bottom: 20px;
      line-height: 1.7;

      align-items: flex-start;

      &__left {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
      }
    }
    &__line2 {

      align-items: center;

    }

    @include sm-only {
      width: 96%;
      padding: 12px;
      margin: 20px auto;
    }

    border: 1px solid white;

    &--collaboration {
      //background-color: $yellow;
      //border: 1px solid $yellow;
      //box-shadow: 0 2px 10px 0 $yellow;
      //&:hover {
      //  @include from-md {
      //    border: 1px solid $dominant1;
      //  }
      //}
    }

    &:hover {

      @include from-md {
        //border: 1px solid $dominant1;
        box-shadow: 0 2px 6px 0 lighten(desaturate($dominant1, 20%), 10%);
      }

    }
  }

}

.shortlist__position {
  //font-family: 'geomanistblack', 'Lato', sans-serif;
  font-weight: bolder;
  font-size: 1.4rem;
  color: $dominant1;
  @include sm-only {
    font-size: 1.2rem;
  }
}

.shortlist-avatars-preview {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 3px;
  overflow: hidden;
  object-fit: cover;
  & img {
    object-fit: cover !important;
    width: 100%;
    height: 100%;
  }
}

.shortlist-number-candidates {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: lighten($dominant1, 50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;

  font-family: 'geomanistblack', 'Lato', sans-serif;
  color: #262626;

  font-size: 1.2rem;
}

.shortlist-avatar {
  object-fit: cover;
  background-color: #ffe2df;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

.shortlist__status {


  //background-color: #f2f2f2;
  color: #2b2b2b;

  opacity: 0.8;

  font-size: 0.94rem;
  font-weight: bolder;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;

  margin: 0 3px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  @include sm-only {
    font-size: 0.9rem;
    //height: 34px;
    //border-radius: 17px;
    padding: 0 15px;
  }

  &--published {
    //background-color: lighten($green, 10%);
    color: desaturate(darken($green, 10%), 10%);
  }

  &--collaboration {
    //background-color: lighten($yellow, 10%);
    color: desaturate(darken($yellow, 10%), 10%);
  }

}