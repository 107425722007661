
.shortlist__time {

  text-align: center;
  margin-right: 20px;
  padding-right: 16px;
  border-right: 1px solid #ccc;

  line-height: 1.4;

  &__numbers {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    color: $violet;
    font-size: 1.7rem;
    @include sm-only {
      font-size: 1.2rem;
    }
  }

  &__unit {
    font-weight: bolder;
    font-size: 1rem;
    opacity: 0.6;
    color: $cyan;
  }

  @include sm-only {
    margin-right: 12px;
    padding-right: 9px;
  }

}
