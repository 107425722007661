
.candidacy-result {
  background-color: $dominant1;
  padding: 40px 0;

  &__graph {

    height: 300px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: flex-end;

    &__column {

      width: 100%;
      max-width: 40px;
      margin: 0 5px;
      @include sm-only {
        margin: 0 4px;
      }

      & div:first-child {
        background-color: lighten($dominant1, 20%);
        margin-bottom: 18px;
        transform-origin: bottom;
        transform: scaleY(0);
        animation: column-grow 1s forwards;
      }

      & div:last-child {
        color: white;
        @include md-only {
          font-size: 0.8rem;
        }
        @include sm-only {
          display: none;
        }
      }

      &--selected {
        & div:first-child {
          background-color: $yellow;
        }
      }

    }

  }

}

.candidacy-result__graph {
  &__column1 {
    // 140
    height: 105px;
  }
  &__column2 {
    // 170
    height: 128px;
  }
  &__column3 {
    //200
    height: 150px;
  }
  &__column4 {
    //260
    height: 195px;
  }
  &__column5 {
    //290
    height: 218px;
  }
  &__column6 {
    //320
    height: 240px;
  }
  &__column7 {
    // 300
    height: 225px;
  }
  &__column8 {
    //280
    height: 210px;
  }
  &__column9 {
    //220
    height: 175px;
  }
  &__column10 {
    //170
    height: 128px;
  }
}

@keyframes column-grow {
  0% { transform: scaleY(0); }
  100% { transform: scaleY(1); }
}

.candidacy-result__title {
  font-family: 'geomanistblack', 'Lato', sans-serif;
  font-size: 1.8rem;
  margin-bottom: 20px;

}